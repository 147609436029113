import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import classNames from 'classnames';
import './Billing.scss';
import requestInstance from '../../../services/AuthService/AccessTokenUpdateSevice';
import { BASE_URL, STRIPE_PUBLIK_KEY } from '../../../api/constants';
import BillingForm from '../../../components/BillingForm/BillingForm';
import visaImg from './images/visa.png';
import mastercardImg from './images/mastercard.png';
import amexImg from './images/amex.png';
import starImg from './images/star.png';
import deleteImg from './images/delete.png';
import wifiImg from './images/wifi.png'
import chipImg from './images/chip.png'
import EmptyPage from '../../../components/EmptyPage/EmptyPage'
const stripePromise = loadStripe(STRIPE_PUBLIK_KEY);

const PaymentMethodCard = ({ paymentMethod, isDefault, onSetDefault, onDelete }) => {
  const brandImg = (brand) => {
    switch (brand) {
      case "VISA":
        return visaImg;
      case "MASTERCARD":
        return mastercardImg;
      case "AMEX":
        return amexImg;
      default:
        return visaImg; 
    }
  };

  return (
    <div className={classNames('PaymentMethodCard', { 'default': isDefault })}>
      <div className='Card__header'>
        <img src={wifiImg} alt='wifiImg' className='Card__icon_wifi'/>
        <div className='Card__actions'>
          <img
            src={starImg}
            alt='set default'
            onClick={() => onSetDefault(paymentMethod.id)}
            className={classNames({ 'yellow-star': isDefault, "Card__icon": true })}
          />
          {isDefault === false && <img
            src={deleteImg}
            alt='delete'
            onClick={() => onDelete(paymentMethod.id)}
            className='Card__icon Card__icon_black'
          />}
        </div>
      </div>

      <img alt='chipImg' src={ chipImg }  className='Card__chip'/>
      <div className='PaymentMethodCard__number'>
        **** **** **** {paymentMethod.card.last4}
      </div>
      <div className='PaymentMethodCard__expiry'>
      {paymentMethod.card.country} {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}
        <img className='PaymentMethodCard__brand' src={brandImg(paymentMethod.card.brand.toUpperCase())} alt={paymentMethod.card.brand} />
      </div>

    </div>
  );
};

export const Billing = () => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);

  const fetchPaymentMethods = async () => {
    try {
      const response = await requestInstance.get(`${BASE_URL}payments/payment-methods/`);
      setPaymentMethods(response.data.results);
    } catch (error) {
      console.error('Error fetching payment methods:', error);
    }
  };

  const fetchDefaultPaymentMethod = async () => {
    try {
      const response = await requestInstance.get(`${BASE_URL}payments/get-default-method/`);
      setDefaultPaymentMethod(response.data.id);
    } catch (error) {
      console.error('Error fetching default payment method:', error);
    }
  };

  const setDefaultPaymentMethodHandler = async (id) => {
    try {
      await requestInstance.post(`${BASE_URL}payments/set-default-method/`, { payment_method_id: id });
      fetchDefaultPaymentMethod();
    } catch (error) {
      console.error('Error setting default payment method:', error);
    }
  };

  const deletePaymentMethodHandler = async (id) => {
    try {
      await requestInstance.delete(`${BASE_URL}payments/delete-payment-method/${id}/`);
      fetchPaymentMethods();
      fetchDefaultPaymentMethod();
    } catch (error) {
      console.error('Error deleting payment method:', error);
    }
  };

  useEffect(() => {
    fetchPaymentMethods();
    fetchDefaultPaymentMethod();
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <div className='Billing'>
        <div className='Billing__current_payment_method'>
          <h2 className='ProductsList__title'>Add payment method</h2>
          <BillingForm onPaymentMethodAdded={() => { fetchPaymentMethods(); fetchDefaultPaymentMethod(); }} />
        </div>
        <h2 className='ProductsList__title'>Payment methods</h2>
        <div className='Billing__payment_methods'>
          {paymentMethods.length > 0 ?
            
              paymentMethods.map((pm) => (
                <PaymentMethodCard
                  key={pm.id}
                  paymentMethod={pm}
                  isDefault={pm.id === defaultPaymentMethod}
                  onSetDefault={setDefaultPaymentMethodHandler}
                  onDelete={deletePaymentMethodHandler}
                />
              ))
            :  <EmptyPage text="No added cards!" />
          }
        </div>
      </div>
    </Elements>
  );
};

export default Billing;
