import './OrderHeader.scss'

export const OrderHeader = () => {
  return(
    <div className='OrderHeader'>
      <h1>Orders</h1>
      <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor <br/> incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>

    </div>
  )
}

export default OrderHeader;