import React, { useState, useEffect } from 'react';
import './StarRating.scss';
import { requestInstance } from '../../services/AuthService/AccessTokenUpdateSevice';
import { BASE_URL } from '../../api/constants';
import { toast } from 'react-toastify';

export const StarRating = ({ rating, totalStars = 5, currentCocktail, setHasReviewed, onRatingSubmit }) => {
  const [currentRating, setCurrentRating] = useState(rating);
  const [hoverRating, setHoverRating] = useState(0);

  useEffect(() => {
    setCurrentRating(rating);
  }, [rating]);

  const handleClick = async (index) => {
    setCurrentRating(index);
    try {
      await requestInstance.post(`${BASE_URL}reviews/cocktails/`, {
        rating: index,
        from_user: JSON.parse(localStorage.getItem('user')).id, 
        to_cocktails: currentCocktail.id
      });
      toast.success('Rating submitted successfully');
      setHasReviewed(true);
      onRatingSubmit();
    } catch (error) {
      console.error('Failed to submit rating:', error);
      toast.error('Failed to submit rating');
    }
  };

  const handleMouseEnter = (index) => {
    setHoverRating(index);
  };

  const handleMouseLeave = () => {
    setHoverRating(0);
  };

  return (
    <div className="StarRating">
      {Array.from({ length: totalStars }, (_, index) => (
        <span
          key={index}
          className={index < (hoverRating || currentRating) ? 'StarRating__filled' : 'StarRating__empty'}
          onClick={() => handleClick(index + 1)}
          onMouseEnter={() => handleMouseEnter(index + 1)}
          onMouseLeave={handleMouseLeave}
          style={{ cursor: 'pointer' }}
        >
          ★
        </span>
      ))}
      <span className="StarRating__text">{currentRating} stars</span>
    </div>
  );
};

export const ReadOnlyStarRating = ({ rating, totalStars = 5 }) => {
  const filledStars = Math.round(rating);
  const starsArray = Array(totalStars).fill(0).map((_, index) => (index < filledStars ? '★' : '☆'));

  return (
    <div className="StarRating">
      {starsArray.map((star, index) => (
        <span key={index} className={star === '★' ? 'StarRating__filled' : 'StarRating__empty'}>
          {star}
        </span>
      ))}
      <span className="StarRating__text">{rating} stars</span>
    </div>
  );
};

export default StarRating;
