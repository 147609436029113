import './Cocktails.scss'
import { useEffect, useState } from 'react';
import { BASE_URL } from '../../../api/constants';
import { requestInstance } from '../../../services/AuthService/AccessTokenUpdateSevice'
import { Pagination } from '../../../components/Pagination/Pagination'
import { EmptyPage } from '../../../components/EmptyPage/EmptyPage'
import star from './images/star.png'
import { useNavigate } from 'react-router-dom';

const LoadingIndicator = () => <></>
export const Cocktails = ({ companyId }) => {
  const [cocktails, setCocktails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0)
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();

  const handleViewCocktailClick = (cocktailId) => {
    navigate(`/cocktails/${cocktailId}`);
  };

  const itemsPerPage = 6; 

  useEffect(() => {
    const getCocktails = async (url) => {
      try {
        setLoading(true);
        const response = await requestInstance.get(url);
        console.log(response.data.results);
        setCocktails(response.data.results);
        setNextPage(response.data.next);
        setPrevPage(response.data.previous);
        setTotalPages(Math.ceil(response.data.count / itemsPerPage));
        setCount(response.data.count)
      } catch (error) {
        console.error('Failed to fetch cocktails:', error);
      } finally {
        setLoading(false);
      }
    };
    if (companyId) {
      let initialUrl = `${BASE_URL}ware-houses/cocktails/?company=${companyId}&limit=${itemsPerPage}`;
      getCocktails(initialUrl);
    }
  }, [companyId]);


  return (
    <>
    <div className='Cocktail'>
      {loading ? (
        <LoadingIndicator />
      ) : (
        cocktails.length > 0 ? (
          cocktails.map((cocktail) => (
            <div key={cocktail.id} className='Cocktail__card'>
              <img className='Cocktail__img' src={cocktail.picture} alt={cocktail.title} />
              <div className='Cocktail__rating'><img src={star} alt='src' /> {cocktail.average_rating}</div>
              <h2 className='Cocktail__title'>{cocktail.title}</h2>
              <button onClick={() => handleViewCocktailClick(cocktail.id)} className='Cocktail__button'>View Cocktail</button>
            </div>
          ))
        ) : (
         <div className='TeamMembersEmpty'> <EmptyPage text="No available cocktails!" /></div>
        ))}
    </div>
     <Pagination
     itemsPerPage={itemsPerPage}
     setObjects={setCocktails}
     setNextPage={setNextPage}
     setPrevPage={setPrevPage}
     nextPage={nextPage}
     prevPage={prevPage}
     currentPage={currentPage}
     totalPages={totalPages}
     setCurrentPage={setCurrentPage}
     count={count}
      />
      </>
  );
};

export default Cocktails;