import axios from 'axios';
import { authService } from './AuthService';

export const requestInstance = axios.create();


requestInstance.interceptors.request.use(
  async config => {
    const access_token = localStorage.getItem('access_token');
    if (access_token) {
      config.headers['Authorization'] = 'Bearer ' + access_token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

requestInstance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const newAccessToken = await authService.refreshToken();
        if (newAccessToken) {
          localStorage.setItem('access_token', newAccessToken);
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + newAccessToken;
          return requestInstance(originalRequest);
        }
      } catch (refreshError) {
        if (refreshError.response && refreshError.response.status === 400) {
          localStorage.removeItem('user');
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          window.location.reload(); 
        }
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);
export default requestInstance;
