import { useEffect, useState, useContext } from 'react';
import './WareHousesFeed.scss';
import {requestInstance} from '../../../services/AuthService/AccessTokenUpdateSevice'
import { BASE_URL } from '../../../api/constants';
import star from './images/star.png'
import { EmptyPage } from '../../../components/EmptyPage/EmptyPage'
import {SearchContext} from '../../../context/SearchContext'
import {Link} from 'react-router-dom'
import { Pagination } from '../../../components/Pagination/Pagination'
  
const LoadingIndicator = () => <></>
export const WareHousesFeed = ({ selectedType, selectedFilters }) => {
  const [wareHouses, setWareHouses] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0)
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true); 
  const itemsPerPage = 12;
  const { searchQuery } = useContext(SearchContext);

  useEffect(() => {
    const getWareHousesFeed = async (offset = 0) => {
      try {
        setLoading(true); 
        let url = `${BASE_URL}ware-houses/?limit=${itemsPerPage}&offset=${offset}`;

        const params = new URLSearchParams();
        
        if (searchQuery) {
          params.append('name', searchQuery)
        }

        if (selectedType) {
          params.append('type_of_ware_house_id', selectedType);
        }

        if (selectedFilters.countries.length > 0) {
          params.append('countries', selectedFilters.countries.join(','));
        }

        if (selectedFilters.ratings.length > 0) {
          params.append('ratings', selectedFilters.ratings.join(','));
        }

        if (selectedFilters.types.length > 0) {
          params.append('types', selectedFilters.types.join(','));
        }

        url += `&${params.toString()}`;
    
        console.log('Request URL:', url);

        const response = await requestInstance.get(url);
        console.log(response.data.results)
        setWareHouses(response.data.results);
        setNextPage(response.data.next);
        setPrevPage(response.data.previous);
        setTotalPages(Math.ceil(response.data.count / itemsPerPage));
        setCount(response.data.count)
      } catch (error) {
        console.error('Failed to fetch warehouses:', error);
      } finally {
        setLoading(false); 
      }
    };
    getWareHousesFeed();
  }, [itemsPerPage, selectedType, selectedFilters, searchQuery]);

  const mainPhoto = (item) => item.ware_house_image.find(img => img.main)?.image;

  return (
    <div className='WareHousesFeed'>
      <div className='WareHousesFeed__container'>
        {loading ? (
          <LoadingIndicator /> 
        ) : wareHouses.length > 0 ? (
          <>
            <div className='WareHousesFeed__wrapper'>
              <div className='WareHousesFeed__slider'>
                  {wareHouses.map((item) => (
                  
                    <div className='WareHousesFeed__item' key={item.id}>
                      <Link to={`/ware-house/${item.id}`}>
                    <img className='WareHousesFeed__image' src={mainPhoto(item)} alt={item.name} />
                    <div className='WareHousesFeed__name'>{item.name}</div>
                    <div className='WareHousesFeed__location'>
                      <div>
                        {item.region} {item.address}
                      </div>
                      <div className='WareHousesFeed__rating'>
                        <img src={star} alt='star' className='WareHousesFeed__star' />
                        {item.average_rating || 0}
                      </div>
                      </div>
                      </Link>
                      </div>
                
                  ))}
                </div>
                 <Pagination
                  itemsPerPage={itemsPerPage}
                  setObjects={setWareHouses}
                  setNextPage={setNextPage}
                  setPrevPage={setPrevPage}
                  nextPage={nextPage}
                  prevPage={prevPage}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setCurrentPage={setCurrentPage}
                  count={count}
                />
            </div>
          </>
        ) : (
          <EmptyPage text="No warehouses available!" />
        )}
      </div>
    </div>
  );
};


export default WareHousesFeed;