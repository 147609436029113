import { useState, useEffect } from 'react';
import './ImageForm.scss';
import { BASE_URL } from '../../api/constants';
import requestInstance from '../../services/AuthService/AccessTokenUpdateSevice';
import classNames from 'classnames';
import starImg from './images/star.png'
import deleteImg from './images/delete.png'


export const ImageForm = ({ objectId, objectType }) => {
  const [images, setImages] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let imagesUrl 
        if (objectType === 'product') {
          imagesUrl = `${BASE_URL}products/product-images/?product_id=${objectId}`;
        } else if (objectType === 'wareHouse'){
          imagesUrl = `${BASE_URL}ware-houses/images/?ware_house_id=${objectId}`;
        } 
        const response = await requestInstance.get(imagesUrl);
        setImages(response.data.results);
      } catch (error) {
        console.log(selectedFile)
        console.error(error);
      }
    };
    fetchData();
  }, [objectId, selectedFile, objectType]);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    handleUploadImage(e.target.files[0]);
  };

  const handleUploadImage = async (file) => {
    const formData = new FormData();
    if (objectType === 'product') {
      formData.append('image', file);
      formData.append('product', objectId);
    } else if (objectType === 'wareHouse') {
      formData.append('image', file);
      formData.append('ware_house', objectId);
    }
    try {
      let url
      if (objectType === 'product') { 
        url = `${BASE_URL}products/product-images/`
      } else if (objectType === 'wareHouse') {
        url = `${BASE_URL}ware-houses/images/`
      }
      const response = await requestInstance.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setImages([...images, response.data]);
      setSelectedFile(null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSetMainImage = async (imageId) => {
    try {
      const data = { main: true };
      let url
      if (objectType === 'product') { 
        url = `${BASE_URL}products/product-images/${imageId}/`
      } else if (objectType === 'wareHouse') {
        url = `${BASE_URL}ware-houses/images/${imageId}/`
      }
      await requestInstance.patch(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      let imagesUrl 
      if (objectType === 'product') {
        imagesUrl = `${BASE_URL}products/product-images/?product_id=${objectId}`;
      } else if (objectType === 'wareHouse'){
        imagesUrl = `${BASE_URL}ware-houses/images/?ware_house_id=${objectId}`;
      } 
      const response = await requestInstance.get(imagesUrl);
      setImages(response.data.results);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteImage = async (imageId) => {
    try {
      let url
      if (objectType === 'product') { 
        url = `${BASE_URL}products/product-images/${imageId}/`
      } else if (objectType === 'wareHouse') {
        url = `${BASE_URL}ware-houses/images/${imageId}/`
      }
      await requestInstance.delete(url);
      setImages(images.filter(image => image.id !== imageId));
    } catch (error) {
      console.error(error);
    }
  };

  return (
  
<div>
      <input
        id="fileInput"
        type="file"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      <div className="images-grid">
        <div onClick={() => document.getElementById('fileInput').click()} className="image-upload-button">
          +
        </div>
        {images.map((image) => (
          <div key={image.id} className="image-container">
            <img src={image.image} alt="product" className={classNames({ "product-image": true, "product-image-main": image.main})} />
            <div className="image-buttons">
              <img className={classNames({ "main-button": true, "main-button-main": image.main})} src={starImg} alt='star' onClick={() => handleSetMainImage(image.id)} />
              {image.main !== true && <img className='delete-button' src={deleteImg} alt='delete' onClick={() => handleDeleteImage(image.id)} />}
            </div>
          </div>
        ))}
      </div>
    </div>

  );
};

export default ImageForm;
