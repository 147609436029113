import React, { useState, useEffect } from 'react';
import './CocatilSideBar.scss';
import { requestInstance } from '../../../services/AuthService/AccessTokenUpdateSevice';
import { BASE_URL } from '../../../api/constants';
import { useNavigate } from 'react-router-dom';


export const CocatilSideBar = () => {
  const [cocktails, setCocktails] = useState([]);
  const itemsPerPage = 10
  const navigate = useNavigate();

  useEffect(() => {
    const getCocktails = async (url) => {
      try {
        const response = await requestInstance.get(url);
        setCocktails(response.data.results);
      } catch (error) {
        console.error('Failed to fetch cocktails:', error);
      } finally {
      }
    };
    
    let initialUrl = `${BASE_URL}ware-houses/cocktails/?limit=${itemsPerPage}`;
    getCocktails(initialUrl);
    
  }, []);
  const handleViewCocktailClick = (cocktailId) => {
    navigate(`/cocktails/${cocktailId}`);
  };

  return (
    <div className='CocatilSideBar' >
      <h2 className='CocatilSideBar__title'>Related</h2>
      {cocktails.map((cocktail) => {
        return(
        <div className='CocatilSideBar__item' onClick={() => handleViewCocktailClick(cocktail.id)}>
            <img src={cocktail.picture} className='CocatilSideBar__img' alt={cocktail.title} />
            <p className='CocatilSideBar__p'>{cocktail.title}</p>
        </div>
      )})}
    </div>
  )
}
export default CocatilSideBar;