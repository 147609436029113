import { useEffect, useState } from 'react';
import './BasketCards.scss';
import { EmptyPage } from '../../../components/EmptyPage/EmptyPage';
import { BASE_URL } from '../../../api/constants';
import { requestInstance } from '../../../services/AuthService/AccessTokenUpdateSevice';
import deleteImg from './images/delete.png';

export const BasketCards = ({ reloadSummary }) => {
  const [productsCards, setProductCards] = useState([]);
  const [expandedCompanies, setExpandedCompanies] = useState({});

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const url = `${BASE_URL}basket/my-basket/`;
      const response = await requestInstance.get(url);
      setProductCards(response.data.results);
      const expandedState = response.data.results.reduce((acc, company) => {
        acc[company.company.id] = true;
        return acc;
      }, {});
      setExpandedCompanies(expandedState);
    } catch (error) {
      console.error('Failed to fetch product:', error);
    }
  };

  const toggleCompanyProducts = (companyId) => {
    setExpandedCompanies((prevState) => ({
      ...prevState,
      [companyId]: !prevState[companyId],
    }));
  };

  const updateProductAmount = async (productId, newAmount) => {
    try {
      const url = `${BASE_URL}basket/update-product-amount/${productId}/`;
      await requestInstance.patch(url, { amount: newAmount });
      fetchProducts(); 
    } catch (error) {
      console.error('Failed to update product amount:', error);
    }
  };

  const handleIncrease = (productItem) => {
    const newAmount = productItem.amount + 1;
    updateProductAmount(productItem.id, newAmount);
    reloadSummary();
  };

  const handleDecrease = (productItem) => {
    const newAmount = Math.max(1, productItem.amount - 1);
    if (newAmount !== productItem.amount) {
      updateProductAmount(productItem.id, newAmount);
      reloadSummary();
    }
  };

  const handleDeleteProduct = async (productItem, companyId) => {
    try {
      const url = `${BASE_URL}basket/delete-product-from-basket/${productItem.id}/`;
      await requestInstance.delete(url);

      const updatedProductsCards = productsCards.map((company) => {
        if (company.company.id === companyId) {
          company.products_list = company.products_list.filter((product) => product.id !== productItem.id);
        }
        return company;
      }).filter((company) => company.products_list.length > 0);

      setProductCards(updatedProductsCards);
      reloadSummary();
    } catch (error) {
      console.error('Failed to delete product from basket:', error);
    }
  };

  const handleDeleteCompanyProducts = async (companyId) => {
    try {
      const companyProducts = productsCards.find((company) => company.company.id === companyId).products_list;
      for (const product of companyProducts) {
        const url = `${BASE_URL}basket/delete-product-from-basket/${product.id}/`;
        await requestInstance.delete(url);
      }
      fetchProducts(); // Fetch updated products after deleting all products of the company
    } catch (error) {
      console.error('Failed to delete company products from basket:', error);
    }
  };

  return (
    <div className="BasketCards">
      <h1>My Cart</h1>
      {productsCards.length === 0 ? (
        <EmptyPage text="Your basket is empty" />
      ) : (
        productsCards.map((productsCard) => (
          <div className="BasketCards__companySection" key={productsCard.company.id}>
            <div className="BasketCards__companyHeader" onClick={() => toggleCompanyProducts(productsCard.company.id)}>
              <div>{productsCard.company.brand_name}</div>
              <div style={{ cursor: 'pointer' }}>{expandedCompanies[productsCard.company.id] ? '^' : 'v'}</div>
            </div>
            {expandedCompanies[productsCard.company.id] && (
              <div className="BasketCards__productsList">
                {productsCard.products_list.map((productItem) => (
                  <div className="BasketCards__productItem" key={productItem.product.id}>
                    <div className="BasketCards__productInfo">
                      <img
                        src={productItem.product.images.find((img) => img.main).image}
                        alt={productItem.product.name}
                        className="BasketCards__productImage"
                      />
                      <div className="BasketCards__productDetails">
                        <div>{productItem.product.name}</div>
                        <div>{productItem.product.category.name} {productItem.product.alcohol_percentage}%</div>
                      </div>
                    </div>
                    <div className="BasketCards__productAmount">
                      <button onClick={() => handleDecrease(productItem)}>-</button>
                      <span>{productItem.amount}</span>
                      <button onClick={() => handleIncrease(productItem)}>+</button>
                    </div>
                    <div className="BasketCards__productPrice">USD {productItem.product.price}</div>
                    <button className="BasketCards__deleteButton" onClick={() => handleDeleteProduct(productItem, productsCard.company.id)}>
                      <img className="BasketCards__deleteImg" src={deleteImg} alt="deleteImg" />
                    </button>
                  </div>
                ))}
                <div className="BasketCards__companyTotal">
                  <div>Total: USD {productsCard.total_amount}</div>
                  <button className="BasketCards__deleteButton" onClick={() => handleDeleteCompanyProducts(productsCard.company.id)}>
                    <img className="BasketCards__deleteImg" src={deleteImg} alt="deleteImg" />
                  </button>
                </div>
              </div>
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default BasketCards;
