import './OrderList.scss'
import { useEffect, useState } from 'react';
import { requestInstance } from '../../../services/AuthService/AccessTokenUpdateSevice';
import { BASE_URL } from '../../../api/constants';
import { MyOrders } from '../MyOrders/MyOrders';
import { OrdersForMe } from '../OrdersForMe/OrdersForMe';
import Pagination from '../../../components/Pagination/Pagination';

export const OrderList = ({ orderListType }) => {
  const [orders, setOrders] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [count, setCount] = useState(0);
  const itemsPerPage = 10; 

  useEffect(() => {
    const fetchData = async (url) => {
      try {
        const response = await requestInstance.get(url);
        setOrders(response.data.results);
        setNextPage(response.data.next);
        setPrevPage(response.data.previous);
        setCount(response.data.count);
        setTotalPages(Math.ceil(response.data.count / itemsPerPage));
      } catch (error) {
        console.error('Failed to fetch orders:', error);
      }
    };

    const myOrdersUrl = `${BASE_URL}orders/${orderListType === 'myOrders' ? 'my-orders/' : 'orders-for-me/'}?limit=${itemsPerPage}&offset=0`;
    fetchData(myOrdersUrl);
  }, [orderListType]);

  return (
    <div className='OrderList'>
      <h2>{orderListType === 'myOrders' ? 'My orders' : 'Orders for me'}</h2>
      {orders.map((order) => 
        orderListType === 'myOrders' 
          ? <MyOrders key={order.id} order={order} /> 
          : <OrdersForMe key={order.id} order={order} />
      )}
      {totalPages > 1 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          setObjects={setOrders}
          setNextPage={setNextPage}
          setPrevPage={setPrevPage}
          nextPage={nextPage}
          prevPage={prevPage}
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
          count={count}
        />
      )}
    </div>
  )
}
