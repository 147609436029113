import './WareHouseList.scss';
import { useEffect, useState, useCallback } from 'react';
import { BASE_URL } from '../../../api/constants';
import requestInstance from '../../../services/AuthService/AccessTokenUpdateSevice';
import { Pagination } from '../../../components/Pagination/Pagination';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import deleteImg from './images/delete.png';
import editImg from './images/edit.png';
import { Link } from 'react-router-dom';

export const WareHouseList = ({ setCurrentForm, setCurrentProduct }) => {
  const [currentCategory, setCurrentCategory] = useState('');
  const [wareHouses, setWareHouse] = useState([]);
  const [category, setCategory] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 5;
  const company_id = JSON.parse(localStorage.getItem('user')).company.id;

  const getData = useCallback(async (offset = 0, category = '') => {
    try {
      const categoryFilter = category ? `&type_of_ware_house_id=${category}` : '';
      const wareHousesUrl = `${BASE_URL}ware-houses/?company_id=${company_id}&limit=${itemsPerPage}&offset=${offset}${categoryFilter}`;
      const categoriesUrl = `${BASE_URL}ware-houses/type/?company_id=${company_id}`;

      const [wareHousesResponse, categoriesResponse] = await Promise.all([
        requestInstance.get(wareHousesUrl),
        requestInstance.get(categoriesUrl)
      ]);

      setWareHouse(wareHousesResponse.data.results);
      setNextPage(wareHousesResponse.data.next);
      setPrevPage(wareHousesResponse.data.previous);
      setTotalPages(Math.ceil(wareHousesResponse.data.count / itemsPerPage));
      setCount(wareHousesResponse.data.count);

      setCategory(categoriesResponse.data.results);
    } catch (error) {
      console.error(error);
      toast.error('Failed to fetch products or categories');
    }
  }, [company_id, itemsPerPage]);

  useEffect(() => {
    const offset = (currentPage - 1) * itemsPerPage;
    getData(offset, currentCategory);
  }, [getData, currentPage, currentCategory]);

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setCurrentCategory(selectedCategory);
    setCurrentPage(1); 
  };

  const mainPhoto = (item) => item.ware_house_image?.find(img => img.main)?.image;

  const handleDeleteWareHouse = async (wareHouseId) => {
    if (window.confirm('Are you sure you want to delete this ware house?')) {
      try {
        await requestInstance.delete(`${BASE_URL}ware-houses/${wareHouseId}/`);
        setWareHouse(wareHouses.filter(wareHouse => wareHouse.id !== wareHouseId));
        toast.success('Ware Houses deleted successfully');
      } catch (error) {
        console.error(error);
        toast.error('Failed to delete ware houses');
      }
    }
  };
  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  };
  return (
    <div className='ProductsList'>
      <ToastContainer />
      <div className='ProductsList__header'>
        <div className='ProductsList__top'>
          <h2 className='ProductsList__title'>My WareHouses ({count})</h2>
          <button
            onClick={() => {
              setCurrentForm('warehouseForm');
              setCurrentProduct(null);
            }} className='ProductsList__add-button'>+ Add new</button>
        </div>
        <div className='ProductsList__category_container'>
          <h3 className='ProductsList__category_title'>Category:</h3>
          <select
            className='ProductsList__category_select'
            value={currentCategory}
            onChange={handleCategoryChange}
          >
            <option value=''>All</option>
            {category.map((cat) => (
              <option value={cat.id} key={cat.id}>{cat.name}</option>
            ))}
          </select>
        </div>
      </div>

      <table className='ProductsList__table'>
        <thead className='ProductsList__table_name'>
          <tr>
            <th className='ProductsList__table-header'>Warehouses</th>
            <th className='ProductsList__table-header'>Description</th>
            <th className='ProductsList__table-header'>Location</th>
            <th className='ProductsList__table-header'style={{width:'80px'}}></th>
          </tr>
        </thead>
        <tbody>
          {wareHouses.map((wareHouse) => {
            console.log(wareHouse); 
            return (
              <tr key={wareHouse.id}>
                <td className='ProductsList__product ProductsList__row_item'>
                <Link to={`../ware-house/${wareHouse.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <img src={mainPhoto(wareHouse)} alt={wareHouse.name} className='ProductsList__product-image' />
                </Link>  
                <Link to={`../ware-house/${wareHouse.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <p>{wareHouse.name}</p>
                </Link>  
                
                </td>
                <td className='ProductsList__row_item'>{truncateString(wareHouse.description, 100)}</td>
                <td className='ProductsList__row_item'>{wareHouse.region} {wareHouse.address} {wareHouse.zip_code}</td>
                <td className='ProductsList__row_item'>
                  <img className='ProductsList__icon' src={deleteImg} alt="delete" onClick={() => handleDeleteWareHouse(wareHouse.id)} />
                  <img className='ProductsList__icon' src={editImg} alt="edit" onClick={() => {
                    setCurrentForm('warehouseForm');
                    setCurrentProduct(wareHouse.id);
                  }}/>
                  </td>
                </tr>
            );
          })}
        </tbody>
      </table>

      <Pagination
        itemsPerPage={itemsPerPage}
        setObjects={setWareHouse}
        setNextPage={setNextPage}
        setPrevPage={setPrevPage}
        nextPage={nextPage}
        prevPage={prevPage}
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
        count={count}
      />
    </div>
  );
};

export default WareHouseList;
