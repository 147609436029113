import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import requestInstance from '../../../services/AuthService/AccessTokenUpdateSevice';
import './PaymentMethod.scss';
import { BASE_URL, STRIPE_PUBLIK_KEY } from '../../../api/constants';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import visaImg from './images/visa.png';
import mcImg from './images/mc.png';
import zImg from './images/z.png';

const stripePromise = loadStripe(STRIPE_PUBLIK_KEY);

const PaymentForm = ({ vat, shipping, deliveryAddressId, reloadSummary }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [loading, setLoading] = useState(false);
  const [vatNumber, setVatNumber] = useState('');
  const [address, setAddress] = useState('');

  const handleRadioChange = (method) => {
    setSelectedMethod(method);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    try {
        if (selectedMethod === 'creditCard') {
            const cardElement = elements.getElement(CardElement);

            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });

            if (error) {
                console.error('Error creating payment method:', error);
                toast.error('Error creating payment method: ' + error.message);
                setLoading(false);
                return;
            }

            const paymentMethodId = paymentMethod.id;

            const response = await requestInstance.post(`${BASE_URL}basket/pay-for-basket/`, {
                vat: vat,
                shipping: shipping,
                payment_method_id: paymentMethodId,
                delivery_address_id: deliveryAddressId,
                vat_number: vatNumber,
                address: address
            });

            if (response.status === 200) {
                toast.success('Payment successful!');
                reloadSummary();
            } else {
                toast.error('Payment failed.');
            }
        } else if (selectedMethod === 'invoice') {
            const response = await requestInstance.post(`${BASE_URL}basket/pay-invoice-for-basket/`, {
                vat: vat,
                shipping: shipping,
                delivery_address_id: deliveryAddressId,
                vat_number: vatNumber,
                address: address
            });

            if (response.status === 200) {
                toast.success('Invoice was successfully created!');
                
                // Download the PDF of the invoice
                const invoicePdfUrl = response.data.invoice_pdf;
                const link = document.createElement('a');
                link.href = invoicePdfUrl;
                link.download = 'invoice.pdf';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                // Redirect to the Stripe-hosted invoice page
                window.location.href = response.data.invoice_url;
            } else {
                toast.error('Invoice creation failed.');
            }
        }
    } catch (err) {
        console.error('Error processing payment:', err);
        toast.error('Payment failed.');
    } finally {
        setLoading(false);
    }
};
  const getCurrentDate = () => {
    const date = new Date();
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  return (
    <div className="PaymentChoice__form">
      <h2>Payment method</h2>

      <div className={`PaymentChoice__option ${selectedMethod === 'creditCard' ? 'checked' : ''}`}>
        <div className='PaymentChoice__header'>
          <div>
            <input
              type="radio"
              name="paymentMethod"
              className='PaymentChoice__checkbox'
              checked={selectedMethod === 'creditCard'}
              onChange={() => handleRadioChange('creditCard')}
            />
            <span>Credit card</span>
          </div>
          <div>
            <img src={visaImg} alt='visa' className='PaymentChoice__card-logo' />
            <img src={mcImg} alt='mc' className='PaymentChoice__card-logo' />
          </div>
        </div>
        {selectedMethod === 'creditCard' && (
          <div className='PaymentChoice__details'>
            <div className='PaymentChoice__input-group'>
              <label>Card details</label>
              <div className="PaymentChoice__card-element">
                <CardElement options={{ hidePostalCode: true }} />
              </div>
            </div>
            <div className='PaymentChoice__ssl-info'>
              <img src={zImg} alt='z' className='PaymentChoice__ssl-logo' />
              <span>Your transaction is secured with SSL encryption</span>
            </div>
          </div>
        )}
      </div>

      <div className={`PaymentChoice__option ${selectedMethod === 'invoice' ? 'checked' : ''}`}>
        <div className='PaymentChoice__header'>
          <div>
            <input
              type="radio"
              name="paymentMethod"
              className='PaymentChoice__checkbox'
              checked={selectedMethod === 'invoice'}
              onChange={() => handleRadioChange('invoice')}
            />
            <span>Invoice</span>
          </div>
        </div>
        {selectedMethod === 'invoice' && (
          <div className='PaymentChoice__details'>
            <div className='PaymentChoice__invoice-detail'>
              <div>
                <label>Date</label>
                <span>{getCurrentDate()}</span>
              </div>
              <div>
                <label>Type</label>
                <span>Alcohol purchase</span>
              </div>
              <div>
                <label>Receipt</label>
                <span><a href="#">HTML</a> | <a href="#">PDF</a></span>
              </div>
            </div>
            <div className="PaymentChoice__input-line">
              <div className='PaymentChoice__input-group'>
                <label>VAT number</label>
                <input
                  type="text"
                  value={vatNumber}
                  onChange={(e) => setVatNumber(e.target.value)}
                  placeholder="Enter number"
                  className='PaymentChoice__input'
                />
              </div>
              <div className='PaymentChoice__input-group'>
                <label>Address</label>
                <input
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  placeholder="Enter address"
                  className='PaymentChoice__input'
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <button onClick={handleSubmit} disabled={loading} className="BasketPaymentMethod-button">
        {loading ? 'Processing...' : 'Confirm order'}
      </button>
      <ToastContainer />
    </div>
  );
};

export const PaymentMethod = ({ vat, shipping, deliveryAddressId, reloadSummary }) => (
  <Elements stripe={stripePromise}>
    <PaymentForm vat={vat} shipping={shipping} deliveryAddressId={deliveryAddressId} reloadSummary={reloadSummary} />
  </Elements>
);

export default PaymentMethod;
