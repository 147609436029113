import './PasswordForm.scss';
import { useState } from 'react';
import openEye from './images/open-eye.png';
import closeEye from './images/close-eye.png';
import requestInstance from '../../../services/AuthService/AccessTokenUpdateSevice';
import { BASE_URL } from '../../../api/constants';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const PasswordForm = () => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    current_password: '',
    new_password: '',
    confirm_password: ''
  });

  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await requestInstance.post(`${BASE_URL}auth/update-password/`, formData);
      console.log(response)
      toast.success('Password updated successfully');
    } catch (error) {
      if (error.response && error.response.data) {
        const errorData = error.response.data;
        Object.keys(errorData).forEach(key => {
          errorData[key].forEach(message => {
            toast.error(message);
          });
        });
      } else {
        toast.error('Failed to update password');
      }
    }
  };

  const handleCancel = () => {
    setFormData({
      current_password: '',
      new_password: '',
      confirm_password: ''
    });
  };

  return (
    <div className="PasswordForm">
      <ToastContainer />
      <div className="PasswordForm__header">
        <h2>Edit Password</h2>
        <div className="PasswordForm__buttons">
          <button className="PasswordForm__button--cancel" type="button" onClick={handleCancel}>Cancel</button>
          <button className="PasswordForm__button--save" type="submit" onClick={handleSubmit}>Save</button>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="PasswordForm__input-group">
          <label htmlFor="current_password">Current Password</label>
          <div className="PasswordForm__input-password">
            <input
              type={showCurrentPassword ? "text" : "password"}
              id="current_password"
              name="current_password"
              value={formData.current_password}
              onChange={handleChange}
            />
            <button
              type="button"
              className="PasswordForm__toggle-password"
              onClick={toggleCurrentPasswordVisibility}
            >
              <img
                src={showCurrentPassword ? openEye : closeEye}
                alt={showCurrentPassword ? "Hide password" : "Show password"}
              />
            </button>
          </div>
        </div>
        <div className="PasswordForm__input-group">
          <label htmlFor="new_password">New Password</label>
          <div className="PasswordForm__input-password">
            <input
              type={showNewPassword ? "text" : "password"}
              id="new_password"
              name="new_password"
              value={formData.new_password}
              onChange={handleChange}
            />
            <button
              type="button"
              className="PasswordForm__toggle-password"
              onClick={toggleNewPasswordVisibility}
            >
              <img
                src={showNewPassword ? openEye : closeEye}
                alt={showNewPassword ? "Hide password" : "Show password"}
              />
            </button>
          </div>
        </div>
        <div className="PasswordForm__input-group">
          <label htmlFor="confirm_password">Confirm Password</label>
          <div className="PasswordForm__input-password">
            <input
              type={showConfirmPassword ? "text" : "password"}
              id="confirm_password"
              name="confirm_password"
              value={formData.confirm_password}
              onChange={handleChange}
            />
            <button
              type="button"
              className="PasswordForm__toggle-password"
              onClick={toggleConfirmPasswordVisibility}
            >
              <img
                src={showConfirmPassword ? openEye : closeEye}
                alt={showConfirmPassword ? "Hide password" : "Show password"}
              />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PasswordForm;

