import { useState } from 'react';
import { WareHousesType } from './WareHousesType/WareHousesType';
import { WareHousesFeed } from './WareHousesFeed/WareHousesFeed';
import { WareHousesBest } from './WareHousesBest/WareHousesBest';
import { Poster } from '../HomePage/Poster/Poster'
import { Reseller } from '../HomePage/Reseller/Reseller'


export const WareHousesPage = () => {
  const [selectedType, setSelectedType] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({
    countries: [],
    ratings: [],
    types: []
  });

  const handleTypeSelect = (type) => {
    setSelectedType(type);
  };
  const handleSelectedFilters = (filters) => {
    setSelectedFilters(filters)
  }
  return (
      <>
      <WareHousesType
        onSelectType={handleTypeSelect}
        selectedType={selectedType}
        onSelectFilters={handleSelectedFilters}
        selectedFilters={ selectedFilters }
      />
      <WareHousesFeed
        selectedType={selectedType}
        selectedFilters={ selectedFilters }
      />
      <WareHousesBest />
      <Poster />
      <Reseller/>
    </>
  )
}

export default WareHousesPage;