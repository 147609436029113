import './Modal.scss';
import { useEffect } from 'react';

export const Modal = ({ show, handleClose, children, title }) => {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [show]);
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="modal-header">
          <h2>{title}</h2>
          <span className="close-button" onClick={handleClose}>x</span>
        </div>
        {children}
      </section>
    </div>
  );
};

export default Modal;