import { useState } from 'react';
import {ProductsType } from './ProductsType/ProductsType';
import { ProductsFeed } from './ProductsFeed/ProductsFeed';
import { Reseller } from '../HomePage/Reseller/Reseller'


export const ProductsPage = () => {
  const [selectedType, setSelectedType] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({
    countries: [],
    ratings: [],
    types: []
  });

  const handleTypeSelect = (type) => {
    setSelectedType(type);
  };
  const handleSelectedFilters = (filters) => {
    setSelectedFilters(filters)
  }
  return (
      <>
      <ProductsType
        onSelectType={handleTypeSelect}
        selectedType={selectedType}
        onSelectFilters={handleSelectedFilters}
        selectedFilters={ selectedFilters }
      />
      <ProductsFeed
        selectedType={selectedType}
        selectedFilters={ selectedFilters }
     />
      <Reseller/>
    </>
  )
}

export default ProductsPage;