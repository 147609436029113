import { OrderHeader } from './OrderHeader/OrderHeader';
import React, { useState } from 'react';
import {OrderList} from './OrderList/OrderList'


export const OrdersPage = () => {
  const [activeButton, setActiveButton] = useState('myOrders');

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  return (
    <div className="OrdersPage">
      <OrderHeader />
      <div className="OrdersPage__buttons">
        <div
          className={`OrdersPage__button ${activeButton === 'myOrders' ? 'OrdersPage__button--active' : 'OrdersPage__button--not-active'}`}
          onClick={() => handleButtonClick('myOrders')}
        >
          My orders
        </div>
        <div className="OrdersPage__separator">|</div>
        <div
          className={`OrdersPage__button ${activeButton === 'orderForMe' ? 'OrdersPage__button--active' : 'OrdersPage__button--not-active'}`}
          onClick={() => handleButtonClick('orderForMe')}
        >
          Orders for me
        </div>
      </div>
      <OrderList orderListType={activeButton}/>
    </div>
  );
};


export default OrdersPage;
