import './Pagination.scss';
import { requestInstance } from '../../services/AuthService/AccessTokenUpdateSevice'
import { useState, useEffect } from 'react'; 

export const Pagination = (
  {
    itemsPerPage,
    setObjects,
    setNextPage,
    setPrevPage,
    nextPage,
    prevPage,
    currentPage,
    totalPages,
    setCurrentPage,
    count
  }) => {

  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);

  useEffect(() => {
    if (nextPage || prevPage) {
      const url = nextPage || prevPage;
      const urlObj = new URL(url);
      const baseUrl = `${urlObj.origin}${urlObj.pathname}`;

      setFirstPage(`${baseUrl}?limit=${itemsPerPage}&offset=0`);
      const totalItems = count;
      const lastPageOffset = (Math.ceil(totalItems / itemsPerPage) - 1) * itemsPerPage;
      setLastPage(`${baseUrl}?limit=${itemsPerPage}&offset=${lastPageOffset}`);
    }
  }, [nextPage, prevPage, itemsPerPage, count]);

  const toNextPage = async () => {
    if (nextPage) {
      try {
        const response = await requestInstance.get(nextPage);
        setObjects(response.data.results);
        setNextPage(response.data.next);
        setPrevPage(response.data.previous);
        setCurrentPage(currentPage + 1);
      } catch (error) {
        console.error('Failed to fetch next page:', error);
      }
    }
  };

  const toPrevPage = async () => {
    if (prevPage) {
      try {
        const response = await requestInstance.get(prevPage);
        setObjects(response.data.results);
        setNextPage(response.data.next);
        setPrevPage(response.data.previous);
        setCurrentPage(currentPage - 1);
      } catch (error) {
        console.error('Failed to fetch previous page:', error);
      }
    }
  };

  const toFirstPage = async () => {
    if (firstPage) {
      try {
        const response = await requestInstance.get(firstPage);
        setObjects(response.data.results);
        setNextPage(response.data.next);
        setPrevPage(response.data.previous);
        setCurrentPage(1);
      } catch (error) {
        console.error('Failed to fetch first page:', error);
      }
    }
  };

  const toLastPage = async () => {
    if (lastPage) {
      try {
        const response = await requestInstance.get(lastPage);
        setObjects(response.data.results);
        setNextPage(response.data.next);
        setPrevPage(response.data.previous);
        setCurrentPage(totalPages);
      } catch (error) {
        console.error('Failed to fetch last page:', error);
      }
    }
  };

  const toPage = async (pageNumber) => {
    const offset = (pageNumber - 1) * itemsPerPage;
    const url = nextPage || prevPage;
    const urlObj = new URL(url);
    const baseUrl = `${urlObj.origin}${urlObj.pathname}`;
    const pageUrl = `${baseUrl}?limit=${itemsPerPage}&offset=${offset}`;
    try {
      const response = await requestInstance.get(pageUrl);
      setObjects(response.data.results);
      setNextPage(response.data.next);
      setPrevPage(response.data.previous);
      setCurrentPage(pageNumber);
    } catch (error) {
      console.error(`Failed to fetch page ${pageNumber}:`, error);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = Math.max(1, currentPage - 3); i <= Math.min(totalPages, currentPage + 3); i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`Pagination__button ${i === currentPage ? 'active' : ''}`}
          onClick={() => toPage(i)}
          disabled={i === currentPage}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  return (
    <>
    { (nextPage || prevPage) &&
    <div className='Pagination'>
      <button className='Pagination__button' onClick={toFirstPage} disabled={currentPage === 1}>«</button>
      <button className='Pagination__button' onClick={toPrevPage} disabled={!prevPage}>‹</button>
      {renderPageNumbers()}
      <button className='Pagination__button' onClick={toNextPage} disabled={!nextPage}>›</button>
      <button className='Pagination__button' onClick={toLastPage} disabled={currentPage === totalPages}>»</button>
    </div>}
    </>
    );
}


export default Pagination;