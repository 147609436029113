import './Footer.scss';
import facebook from './images/facebook.png';
import twitter from './images/twitter.png';
import instagram from './images/instagram.png'
import {Link} from 'react-router-dom';


export const Footer = () => {
  return (
    <footer className="Footer">
      <div className="Footer__content">
        <div className="Footer__section Footer__section--brand">
          <h2 className="Footer__logo">Tyrrst</h2>
          <p className="Footer__mission">
            Our Mission: To battle the [near absurd] complexity of the global alcohol industry by bringing craft and independent brands in direct contact with resellers and providing the necessary processes for their interactions.
          </p>
        </div>
        <div className="Footer__section">
          <h3>Services</h3>
          <ul>
            <li><Link to='subscriptions/'>Subscription</Link></li>
            <li><Link to='ware-houses/'>Ware houses</Link></li>
            <li><Link to='locations/'>Locations</Link></li>
            <li><Link to='products/'>Products</Link></li>
          </ul>
        </div>
        <div className="Footer__section">
          <h3>About</h3>
          <ul>
            <li><Link to=''>Home</Link></li>
            <li>Benefits</li>
            <li>Team</li>
            <li>Careers</li>
          </ul>
        </div>
        <div className="Footer__section">
          <h3>Follow Us</h3>
          <ul>
            <li><img className="SocialIcons" src={facebook} alt='Facebook' /> Facebook</li>
            <li><img className="SocialIcons" src={twitter} alt='Twitter' /> Twitter</li>
            <li><img className="SocialIcons" src={instagram} alt='Instagram' /> Instagram</li>
          </ul>
        </div>
      </div>
      <div className="Footer__bottom">
        <p>Copyright © 2020. Vestigo. All rights reserved.</p>
        <div>
          <a href="#terms">Terms & Conditions</a>
          <a href="#privacy">Privacy Policy</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer