import './OrdersForMe.scss';
import { useState } from 'react';
import { requestInstance } from '../../../services/AuthService/AccessTokenUpdateSevice';
import { BASE_URL } from '../../../api/constants';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';

export const OrdersForMe = ({ order }) => {
  const [status, setStatus] = useState(order.order_status);

  const handleStatusChange = async (event) => {
    const newStatus = event.target.value;
    setStatus(newStatus);
    try {
      await requestInstance.patch(`${BASE_URL}orders/orders-for-me/${order.id}/`, {
        order_status: newStatus,
      });
      toast.success('Order status updated successfully');
    } catch (error) {
      toast.error('Failed to update order status:', error);
    }
  };

  return (
    <div className="OrdersForMe">
      {order.order_items.map((item) => (
        <div key={item.id} className="OrdersForMe__item">
          <img src={item.product.images[0].image} alt={item.product.name} className="OrdersForMe__item-image" />
          <div className="OrdersForMe__item-details">
            <h3>{item.product.name}</h3>
            <p>Qty: {item.amount}</p>
            <p>Price: ${item.price.toFixed(2)}</p>
          </div>
          <div className="OrdersForMe__item-status">
            <label htmlFor={`status-${order.id}`}>Status:</label>
            <select
              id={`status-${order.id}`}
              value={status}
              onChange={handleStatusChange}
            >
              <option value="pending">Pending</option>
              <option value="processing">Processing</option>
              <option value="shipped">Shipped</option>
              <option value="delivered">Delivered</option>
              <option value="cancelled">Cancelled</option>
            </select>
          </div>
          <div className="OrdersForMe__item-delivery">
            <h4>Delivery Address:</h4>
            <p>{order.delivery_address.first_name} {order.delivery_address.last_name}</p>
            <p>{order.delivery_address.address_line_1}</p>
            <p>{order.delivery_address.city}, {order.delivery_address.state}</p>
            <p>{order.delivery_address.zip_code}</p>
          </div>
        </div>
      ))}
      <div className="OrdersForMe__total">
        <h3>Total Amount: ${order.total_amount.toFixed(2)}</h3>
      </div>
      <ToastContainer />
    </div>
  );
};

export default OrdersForMe;
