import './App.css';
import { UserProvider } from './context/UserContext.js';
import { BrowserRouter as Router } from 'react-router-dom';
import { Main } from './components/Main.jsx'



const App = () => {


  return (
    
    <UserProvider >
      <Router >
        <Main />
      </Router>
    </UserProvider>

  );
}

export default App;
