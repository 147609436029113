import { LeadList } from './LeadList/LeadList'

export const LeadPage = () => {
  return (
    <div>
      <LeadList />
    </div>
  )
}

export default LeadPage;