import { BasketHeaderButtons } from './BasketHeaderButtons/BasketHeaderButtons';
import { BasketCards } from './BasketCards/BasketCards'; 
import { BasketSummary } from './BasketSummary/BasketSummary'
import { ShippingAddress } from './ShippingAddress/ShippingAddress';
import { PaymentMethod } from './PaymentMethod/PaymentMethod';
import { useState } from 'react';

export const BasketPage = () => {
  const [blockVisability, setBlockVisability] = useState(true);
  const [vat, setVat] = useState(true);
  const [shipping, setShipping] = useState('Simple');
  const [deliveryAddressId, setDeliveryAddressId] = useState(0);
  const [summaryReloadKey, setSummaryReloadKey] = useState(0);

  const reloadSummary = () => {
    setSummaryReloadKey((prevKey) => prevKey + 1);
  };

  return (
    <div>
      <BasketHeaderButtons />
      <div style={{ display: 'flex' }}>
        <div>
          {blockVisability && <BasketCards reloadSummary={reloadSummary} />}
          {!blockVisability && <ShippingAddress setDeliveryAddressId={setDeliveryAddressId} />}
          {!blockVisability && (
            <PaymentMethod
              vat={vat}
              shipping={shipping}
              deliveryAddressId={deliveryAddressId}
              reloadSummary={reloadSummary}
            />
          )}
        </div>
        <BasketSummary
          key={summaryReloadKey}
          setBlockVisability={setBlockVisability}
          blockVisability={blockVisability}
          setVat={setVat}
          setShippingf={setShipping}
        />
      </div>
    </div>
  );
};

export default BasketPage;