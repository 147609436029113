import './ActionHeaderButtons.scss';
import { useNavigate } from 'react-router-dom';
import { requestInstance } from '../../../services/AuthService/AccessTokenUpdateSevice';
import { BASE_URL } from '../../../api/constants';
import { toast } from 'react-toastify';

export const ActionHeaderButtons = ({ currentProduct }) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); 
  };

  const handleEditClick = () => {
    navigate('/profile', { state: { currentForm: 'productForm', productId: currentProduct.id } });
  };

  const handleDeleteProduct = async () => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        await requestInstance.delete(`${BASE_URL}products/${currentProduct.id}/`);
        toast.success('Product deleted successfully');
        navigate(-1);
      } catch (error) {
        console.error('Failed to delete product:', error);
        toast.error('Failed to delete product');
      }
    }
  };

  return (
    <div className='ActionHeaderButtons__container'>
      <button className='ActionHeaderButtons__back' onClick={handleBackClick}>{'< '}Back</button>
      {currentProduct.is_my === true ? (
        <div className='ActionHeaderButtons__buttons'>
          <button className='ActionHeaderButtons__first_button' onClick={handleDeleteProduct}>Delete product</button>
          <button className='ActionHeaderButtons__second_button' onClick={handleEditClick}>Edit product</button>
        </div>
      ) : (
        <div className='ActionHeaderButtons__buttons'>
          <button className='ActionHeaderButtons__first_button'>Add to favourite</button>
          <button className='ActionHeaderButtons__second_button'>Buy Now</button>
        </div>
      )}
    </div>
  );
};

export default ActionHeaderButtons;
