import './Features.scss';

export const Features = () => {
  return (
    <div className="Features">
      <h2 className="Features__title">Features</h2>
      <ul className="Features__list">
        <li className="Features__list__item Features__list__item--large">
          <h3 className="Features__list__item__title">Easy access</h3>
          <p className="Features__list__item__text">
            Access a broader range of customers across Europe
            <br />
            and grow your business with our easy-to-use
            <br />
            solutions for producers and resellers, no matter how
            <br />
            big or small.
          </p>
        </li>
        <li className="Features__list__item">
          <h3 className="Features__list__item__title">Easy payment</h3>
          <p className="Features__list__item__text">
            Never send an invoice or
            <br />
            chase a dollar of payment
            <br />
            again and say goodbye to
            <br />
            those sleepless nights
            <br />
            worrying about cashflow.
          </p>
        </li>
        <li className="Features__list__item">
          <h3 className="Features__list__item__title">Easy fulfilling</h3>
          <p className="Features__list__item__text">
            Specialised solutions with
            <br />
            bonded storage, oder pick
            <br />
            up, shipping direct to
            <br />
            customers, pick pack
            <br />
            and ship awrvice.
          </p>
        </li>
        <li className="Features__list__item">
          <h3 className="Features__list__item__title">Easy ordering</h3>
          <p className="Features__list__item__text">
            Manage all of your
            <br />
            customers’ orders in one
            <br />
            place with no more
            <br />
            messy emails, phone
            <br />
            calls or texts.
          </p>
        </li>
        <li className="Features__list__item">
          <h3 className="Features__list__item__title">Easy account management</h3>
          <p className="Features__list__item__text">
            Reduce friction in your
            <br />
            sales process and remove
            <br />
            those time consuming
            <br />
            credit apps.
          </p>
        </li>
        <li className="Features__list__item">
          <h3 className="Features__list__item__title">Easy reporting</h3>
          <p className="Features__list__item__text">
            Keep up to date and better
            <br />
            manage your sales
            <br />
            operations with real-time
            <br />
            reporting.
          </p>
        </li>
        <li className="Features__list__item">
          <h3 className="Features__list__item__title">Easy growth</h3>
          <p className="Features__list__item__text">
            Unlike many other
            <br />
            platforms providers, our
            <br />
            beverage platform has
            <br />
            been developped to growth
            <br />
            in HORECA as well.
          </p>
        </li>
      </ul>
    </div>
  );
};


export default Features;