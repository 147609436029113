import './Poster.scss'

export const Poster = () => {
  return (
    <div className="Poster">
      <div className="Poster__overlay">
        <h2 className="Poster__title">Register your brand and sell</h2>
        <button className="Poster__button">Start selling</button>
      </div>
    </div>
  )
}
export default Poster;