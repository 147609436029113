import React, { createContext, useContext, useState, useEffect } from 'react';
import { BASE_URL } from '../api/constants';
import { requestInstance } from '../services/AuthService/AccessTokenUpdateSevice'


const UserContext = createContext();

export const useUser = () => useContext(UserContext);


export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  useEffect(() => {
    const fetchUser = async () => {
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        const accessToken = localStorage.getItem('access_token');
        if (accessToken) {
          try {
            const url = BASE_URL + 'auth/me/'
            const response = await requestInstance.get(url);
            const userData = response.data;
            console.log('Fetched user data: 1', userData);
            setUser(userData);
            localStorage.setItem('user', JSON.stringify(userData));
          } catch (error) {
            console.error('Error fetching user:', error);
          }
        }
      }
    };

    fetchUser();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};