import './LoginForm.scss';
import { useState } from 'react';
import { saveTokens } from '../../services/TokenService/tokens';
import openEye from './images/open-eye.png';
import closeEye from './images/close-eye.png';
import lock from './images/lock.png';
import { useUser } from '../../context/UserContext';
import { BASE_URL } from '../../api/constants';
import { authService } from '../../services/AuthService/AuthService'
import {requestInstance} from '../../services/AuthService/AccessTokenUpdateSevice'


export const LoginForm = ({ setIsLoginFormVisible, setIsRegisterFormVisible, setIsForgotPasswordForm1Visible } ) => {
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isError, setIsError] = useState(false)
  const { setUser } = useUser();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const result = await authService.login(username, password);
      if (result) {
        saveTokens(result);
        setIsLoginFormVisible(false);
        const url = BASE_URL + 'auth/me/'
        const response = await requestInstance.get(url);
        const userData = response.data;
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('companyID', `${userData.company.id}`);
        setUser(userData)
        window.location.reload();
        console.log('Login successful:', result);
      } else {
        console.log('Login failed');
        setIsError(true);
      }
    } catch (error) {
      console.error('Login failed:', error);
      setIsError(true);
    }
  };
  return (
    <div className="LoginForm">
      <h2 className="LoginForm__title">
        Log in
        <span className='closeForm' onClick={() => setIsLoginFormVisible(false)}>x</span>
      </h2> 
      <form className="LoginForm__form" method='post' onSubmit={handleSubmit}>
        <div className="LoginForm__input-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            name="username"
            onChange={
              (e) => {
                setUsername(e.target.value)
                setIsError(false)
              }} />
        </div>
        <div className="LoginForm__input-group">
          <label htmlFor="password">Password</label>
          <div className="LoginForm__input-password">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              onChange={(e) => {
                setPassword(e.target.value)
                setIsError(false)
              }}
            />
            <button
              type="button"
              className="LoginForm__toggle-password"
              onClick={togglePasswordVisibility}
            >
              <img
                src={showPassword ? openEye : closeEye}
                alt={showPassword ? "Hide password" : "Show password"}
              />
            </button>
          </div>
        </div>
        {isError && <span className='ErrorText'>Incorrect credentials</span>}
        <div className="LoginForm__checkbox-group">
          <input type="checkbox" id="rememberMe" name="rememberMe" />
          <label htmlFor="rememberMe">Remember me</label>
        </div>
        <button type="submit" className="LoginForm__submit">Log in</button>
      </form>
      <div className="LoginForm__footer">
        <a href="#forgot" className="LoginForm__forgot"
        onClick={() => {
              setIsLoginFormVisible(false)
              setIsForgotPasswordForm1Visible(true)
            }}><img className="Lock" src={lock} alt='lock' /> Forgot your password?</a>
        <p>
          Don’t have an account? .
          <a href="#signup" className="LoginForm__signup"
            onClick={() => {
              setIsLoginFormVisible(false)
              setIsRegisterFormVisible(true)
            }}>
            Sign up now
          </a>
        </p>
      </div>
    </div>
  );
};

export default LoginForm;