import './UserForm.scss';
import { useState } from 'react';
import openEye from './images/open-eye.png';
import closeEye from './images/close-eye.png';
import lock from './images/lock.png';
import { requestInstance } from '../../../services/AuthService/AccessTokenUpdateSevice'
import { BASE_URL } from '../../../api/constants';


export const UserForm = ({ setIsUserFormVisible, setIsEmailConfirmationFormVisible }) => {
  const [showPassword1, setShowPassword1] = useState(false);
  const [errors, setErrors] = useState({})
  const [userFormData, setUserFormData] = useState({
      username: "",
      company_id: localStorage.getItem('companyID'),
      password: "",
      confirm_password: ""

  })

  const handleChange = (e) => {
    setErrors({})
    const { name, value } = e.target;
    setUserFormData({ ...userFormData, [name]: value });
  };

  const togglePassword1Visibility = () => {
    setShowPassword1(!showPassword1);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const url = BASE_URL + 'auth/registration-2-step/'
      const result = await requestInstance.post(url, userFormData);
      if (result) {
        console.log(result)
        setIsUserFormVisible(false)
        setIsEmailConfirmationFormVisible(true)
      } else {
        console.log('Login failed');
      }
    } catch (error) {
      console.error('Login failed:', error);
      setErrors(error.response.data);
    }
  };
  return (
    <div className="LoginForm">
      <h2 className="LoginForm__title">
        Create user
        <span style={{ right: '-120px' }} className='closeForm' onClick={() => setIsUserFormVisible(false)}>x</span>
      </h2>
      <form className="LoginForm__form" method='post' onSubmit={handleSubmit}>
        <div className="LoginForm__input-group">
          <label htmlFor="username">Choose username</label>
          <input
            type="text"
            id="username"
            name="username"
            value={userFormData.username}
            onChange={handleChange} />
          {errors.username && <p className="ErrorText">{errors.username[0]}</p>}
        </div>
        <div className="LoginForm__input-group">
          <label htmlFor="password">Choose password</label>
          <div className="LoginForm__input-password">
            <input
              type={showPassword1 ? "text" : "password"}
              id="password"
              name="password"
              value={userFormData.password}
              onChange={handleChange}
            />
            <button
              type="button"
              className="LoginForm__toggle-password"
              onClick={togglePassword1Visibility}
            >
              <img
                src={showPassword1 ? openEye : closeEye}
                alt={showPassword1 ? "Hide password" : "Show password"}
              />
            </button>
            {errors.password && <p className="ErrorText">{errors.password[0]}</p>}
          </div>
        </div>
        
        <div className="LoginForm__input-group">
          <label htmlFor="password">Confirm password</label>
          <div className="LoginForm__input-password">
            <input
              type={showPassword1 ? "text" : "password"}
              id="confirm_password"
              name="confirm_password"
              value={userFormData.confirm_password}
              onChange={handleChange}
            />
            <button
              type="button"
              className="LoginForm__toggle-password"
              onClick={togglePassword1Visibility}
            >
              <img
                src={showPassword1 ? openEye : closeEye}
                alt={showPassword1 ? "Hide password" : "Show password"}
              />
            </button>
            {errors.confirm_password && <p className="ErrorText">{errors.confirm_password[0]}</p>}
          </div>
        </div>

        <div className="LoginForm__checkbox-group">
          <input type="checkbox" id="rememberMe" name="rememberMe" />
          <label htmlFor="rememberMe">Remember me</label>
        </div>
        <button type="submit" className="LoginForm__submit">Log in</button>
      </form>
      <div className="LoginForm__footer">
        <p className="LoginForm__forgot"><img className="Lock" src={lock} alt='lock'/> I haven't recived any confirmation email</p>
      </div>
    </div>
  );
};

export default UserForm;