import './TeamMembers.scss'
import { useEffect, useState } from 'react';
import { BASE_URL } from '../../../api/constants';
import { requestInstance } from '../../../services/AuthService/AccessTokenUpdateSevice'
import { Pagination } from '../../../components/Pagination/Pagination'
import { EmptyPage } from '../../../components/EmptyPage/EmptyPage'

const LoadingIndicator = () => <></>
export const TeamMembers = ({ wareHouseId }) => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0)
  const [totalPages, setTotalPages] = useState(0);

  const itemsPerPage = 8; 

  useEffect(() => {
    const getTeamMembers = async (url) => {
      try {
        setLoading(true);
        const response = await requestInstance.get(url);
        console.log(response.data.results);
        setTeamMembers(response.data.results);
        setNextPage(response.data.next);
        setPrevPage(response.data.previous);
        setTotalPages(Math.ceil(response.data.count / itemsPerPage));
        setCount(response.data.count)
      } catch (error) {
        console.error('Failed to fetch team members:', error);
      } finally {
        setLoading(false);
      }
    };
    if (wareHouseId) {
      let initialUrl = `${BASE_URL}ware-houses/team-member/?warehouse_id=${wareHouseId}&limit=${itemsPerPage}`;
      getTeamMembers(initialUrl);
    }
  }, [wareHouseId]);


  return (
    <>
    <div className='TeamMembers'>
      {loading ? (
        <LoadingIndicator />
      ) : (
        teamMembers.length > 0 ? (
          teamMembers.map((teamMember) => (
            <div key={teamMember.id} className='TeamMembers__card'>
              <div className='TeamMembers__header'> 
              <img className='TeamMembers__img' src={teamMember.photo} alt={ teamMember.full_name } />
              <div className='TeamMembers__details'>
                <div className='TeamMembers__full_name'>{teamMember.full_name}</div>
                <div className='TeamMembers__position'>{teamMember.position}</div>
                <div className='TeamMembers__joined_at'>Joined at {teamMember.joined_at}</div>
              </div>
              </div>
              <div className='TeamMembers__description'>{teamMember.description}</div>
            </div>
          ))
        ) : (
         <div className='TeamMembersEmpty'> <EmptyPage text="No available team member!" /></div>
        ))}
    </div>
     <Pagination
     itemsPerPage={itemsPerPage}
     setObjects={setTeamMembers}
     setNextPage={setNextPage}
     setPrevPage={setPrevPage}
     nextPage={nextPage}
     prevPage={prevPage}
     currentPage={currentPage}
     totalPages={totalPages}
     setCurrentPage={setCurrentPage}
     count={count}
      />
      </>
  );
};


export default TeamMembers;