import { useEffect, useState } from "react"
import { BASE_URL } from "../../../api/constants"
import { requestInstance } from '../../../services/AuthService/AccessTokenUpdateSevice'
import categoryImg from './images/category.png'
import plusImg from './images/plus.png'
import './SideBar.scss';
import classNames from "classnames"
import { useNavigate } from 'react-router-dom';

export const SideBar = ({ currenWareHouse, setProductFilter, productFilter  }) => {
  const [wareHouseCategories, setWarehouseCategories] = useState([])
  const navigate = useNavigate();
  useEffect(
    () => {
      const getCurrenWareHouseTypes = async () => {
        try {
          const url = `${BASE_URL}products/catrgories/warehouse/${currenWareHouse.id}`
          const response = await requestInstance.get(url)
          setWarehouseCategories(response.data.categories)
          console.log(response.data)
        } catch (error) {
          console.error(error)
        }
      }
      getCurrenWareHouseTypes() 
    }, [currenWareHouse]
  )
  const handleEditProducts = () => {
    navigate('/profile', { state: { currentForm: 'products' } });
  };
  return (
    <div className="SideBar">
      <div className="SideBar__title">Catigories by {currenWareHouse.name}</div>
      {wareHouseCategories.length > 0 && 
        <div className="SideBar__categories">
          <img className="SideBar__icon" src={categoryImg} alt='category' />
          <span
            className={classNames({ "SideBar__text": true, "SideBar__text__chousen": productFilter === ''})}
            onClick={() => { setProductFilter('') }}
          >
            All
          </span>
        </div>
      }
      {wareHouseCategories.length > 0 ? (
        
          wareHouseCategories.map(
            (category) =>
              <div key={category.id} className="SideBar__categories">
                <img className="SideBar__icon" src={categoryImg} alt='category' />
                <span
                  className={classNames({ "SideBar__text": true, "SideBar__text__chousen": productFilter === category.id})}
                  onClick={() => { setProductFilter(category.id) }}>
                  {category.name}
                </span>
              </div>
          )
      ) : (
          <div className="SideBar__categories">
            <img className="SideBar__icon" src={plusImg} alt="plus"/>
            <span className="SideBar__text">Product not added yet!!!</span>
          </div>
      )}
      {
        currenWareHouse.is_my_warehouse === false ?
        (<button className="SideBar__button">
            Contact Lead
          </button>) : (
            <button className="SideBar__button SideBar__button__black" onClick={handleEditProducts}>
              Edit Products
            </button>)
        }
    </div>
  )
}

export default SideBar