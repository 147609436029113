import './WareHousesDescription.scss'

export const WareHousesDescription = ({wareHouseDescription}) => {
  return (
    <div className='WareHousesDescription'>
      <h2 className='WareHousesDescription__title'>About the company</h2>
      {wareHouseDescription ?
        <p className='WareHousesDescription__content'>{wareHouseDescription}</p> :
        <p className='WareHousesDescription__content' >Warehouse not added description yet!</p>
        }
    </div>
  )
}

export default WareHousesDescription;