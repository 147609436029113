import './CoctailHeaderBattons.scss'
import { useNavigate } from 'react-router-dom';

export const CoctailHeaderBattons = ({ currentCocktail }) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); 
  };
  return (
    <div className='ActionHeaderButtons__container'>
      <button className='ActionHeaderButtons__back' onClick={handleBackClick}>{'< '}Back</button>
     
        <div className='ActionHeaderButtons__buttons'>
          <button className='ActionHeaderButtons__first_button'>Add to favourite</button>
          <button className='ActionHeaderButtons__second_button'>Contact</button>
    </div>
</div>)
}

export default CoctailHeaderBattons;