import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

export const PrivateRoute = ({ element: Component, isAuthenticated, ...rest }) => {
  useEffect(() => {
    if (!isAuthenticated) {
      const loginLink = document.querySelector('.Header__register span:first-child');
      if (loginLink) {
        loginLink.click();
      }
    }
  }, [isAuthenticated]);

  return isAuthenticated ? Component : <Navigate to="/" />;
};

export default PrivateRoute;