import './Review.scss'
import { useEffect, useState } from 'react';
import { BASE_URL } from '../../../api/constants';
import { requestInstance } from '../../../services/AuthService/AccessTokenUpdateSevice'
import { Pagination } from '../../../components/Pagination/Pagination'
import { EmptyPage } from '../../../components/EmptyPage/EmptyPage'
import star from './images/star.png'


const LoadingIndicator = () => <></>
export const Review = ({ wareHouseId }) => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0)
  const [totalPages, setTotalPages] = useState(0);

  const itemsPerPage = 15; 

  useEffect(() => {
    const getReview = async (url) => {
      try {
        setLoading(true);
        const response = await requestInstance.get(url);
        console.log(response.data.results);
        setReviews(response.data.results);
        setNextPage(response.data.next);
        setPrevPage(response.data.previous);
        setTotalPages(Math.ceil(response.data.count / itemsPerPage));
        setCount(response.data.count)
      } catch (error) {
        console.error('Failed to fetch gallery:', error);
      } finally {
        setLoading(false);
      }
    };
    if (wareHouseId) {
      let initialUrl = `${BASE_URL}reviews/?warehouse_id=${wareHouseId}&limit=${itemsPerPage}`;
      getReview(initialUrl);
    }
  }, [wareHouseId]);


  return (
    <>
      <div className='Reviews'>
        <h2 className='Reviews__title'>Reviews</h2>
      {loading ? (
        <LoadingIndicator />
        ) : (
        reviews.length > 0 ? (
          reviews.map((item) => (
            <div key={item.id} className='Reviews__card'>
              <div className='Reviews__header'>
                <div className='Reviews__user'> 
                <img className='Reviews__user_photo' src={item.from_user.user_photo} alt='user' />
                <h3 className='Reviews__user_name'>{item.from_user.company.first_name} {item.from_user.company.last_name}</h3>
                <div className='Reviews__date'> {item.date_creation} </div>
                </div>
              <div> 
                <img src={star} alt='star' className='Reviews__star' />
                <p className='Reviews__rating'>{ item.rating }</p>
              </div>
              </div>
              <p className='Reviews__comment'>{item.comment} </p>
            </div>
          ))
        ) : (
         <div className='TeamMembersEmpty'> <EmptyPage text="No reviws!" /></div>
        ))}
    </div>
     <Pagination
     itemsPerPage={itemsPerPage}
     setObjects={setReviews}
     setNextPage={setNextPage}
     setPrevPage={setPrevPage}
     nextPage={nextPage}
     prevPage={prevPage}
     currentPage={currentPage}
     totalPages={totalPages}
     setCurrentPage={setCurrentPage}
     count={count}
      />
      </>
  );
};

export default Review;