import { useState, useEffect } from 'react';
import { requestInstance } from '../../../services/AuthService/AccessTokenUpdateSevice';
import { BASE_URL } from '../../../api/constants';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ProductPrice.scss';

export const ProductPrice = ({ currentProduct }) => {
  const [products, setProducts] = useState([]);
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const url = `${BASE_URL}products/?limit=5`;
        const response = await requestInstance.get(url);
        setProducts(response.data.results);
      } catch (error) {
        console.error('Failed to fetch products:', error);
      }
    };

    fetchProducts();
  }, []);

  const handleAddToBasket = async () => {
    try {
      const url = `${BASE_URL}basket/add-product-to-basket/`;
      const response = await requestInstance.post(url, {
        product_id: currentProduct.id,
        amount: quantity,
      });
      if (response.status === 201) {
        toast.success('Product added to basket successfully!');
      } else {
        toast.error('Failed to add product to basket');
      }
    } catch (error) {
      console.error('Failed to add product to basket:', error);
      toast.error('Failed to add product to basket');
    }
  };

  return (
    <div className='ProductPrice'>
      <div className='ProductPrice__name'>{currentProduct.name}</div>
      <div className='ProductPrice__SKU'>SKU: {currentProduct.id}</div>
      <div className='ProductPrice__price'>{currentProduct.currency} {currentProduct.price}</div>
      <input 
        className='ProductPrice__input'
        type="number" 
        value={quantity} 
        onChange={(e) => setQuantity(Math.max(1, e.target.value))} 
        min="1"
      />
      <button className='ProductPrice__button' onClick={handleAddToBasket}>Add to basket</button>
      <div className='ProductPrice__product-list'>
        <h3>Products</h3>
        {products.length > 0 ? (
          <ol>
            {products.map((product) => {
              const mainImage = product.images.find(image => image.main)?.image;
              return (
                <li key={product.id}>
                  <Link to={`/product/${product.id}`}>
                    <img className='ProductPrice__img' src={mainImage} alt={product.name} />
                  </Link>
                  {product.name}
                </li>
              );
            })}
          </ol>
        ) : (
          <p>No products found</p>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default ProductPrice;
