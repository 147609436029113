import './Subscription.scss';
import { SubscriptionType } from '../SubscriptionType/SubscriptionType'
import {getSubscription} from '../../../api/subscribtion/getSubscriptions'
import { useState, useEffect } from 'react';
import {requestInstance} from '../../../services/AuthService/AccessTokenUpdateSevice'
import { BASE_URL } from '../../../api/constants';
import { PaymentModal } from '../../../components/PaymentModal/PaymentModal'

export const Subscription = ({ isButton }) => {
  const [listSubscriptions, setListSubscriptions] = useState([]);
  const [currentSub, setCurrentSub] = useState(null);
  const [userId, setUserId] = useState(null);
  const [visibilityPaymentModal, setVisibilityPaymentModal] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(null);

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      setUserId(JSON.parse(user).id);
    }
  }, []);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const subscriptions = await getSubscription();
        setListSubscriptions(subscriptions.results || []);
      } catch (error) {
        console.error('Failed to fetch subscriptions:', error);
      }
    };

    fetchSubscriptions();
  }, []);

  useEffect(() => {
    const getMyCurrentSub = async () => {
      try {
        const url = BASE_URL + 'subscriptions/my-current-sub/';
        const response = await requestInstance.get(url);
        setCurrentSub(response.data.subscription.id);
        console.log(response);
      } catch (error) {
        console.error('Failed to fetch current subscription:', error);
      }
    };

    if (isButton) {
      getMyCurrentSub();
    }
  }, [isButton]);

  const handleSubscriptionChange = (newSubId) => {
    setCurrentSub(newSubId);
  };

  return (
    <div className="Subscription">
      {userId && visibilityPaymentModal && (
        <div className="ModalOverlay">
          <PaymentModal
            onClose={() => setVisibilityPaymentModal(false)}
            subscriptionId={subscriptionId}
            userId={userId}
            setCurrentSub={(item) => {setCurrentSub(item)}}
          />
        </div>
      )}
      <h1 className="Subscription__title">Choose your subscription</h1>
      <p className="Subscription__text">
        You can choose from three membership plans: Freeby, Premium and Enterprise. Freeby gives you access to the 
        Tyrrst marketplace and puts you in contact with resellers across {'>'}10 European countries - but you need to 
        take care of the logistics yourself; Premium and Enterprise levels allow you to use Tyrrst Logistics including 
        free storage and low cost, small batch excise and delivery services as well as be part of Tyrrst Marketing and 
        promotion events. We also offer a standard payment option where you receive your payment in 30 days or an 
        accelerated payment alternative where you can receive payment in just 5 days.
      </p>
      <div className="Subscription__box">
        {listSubscriptions.map(subscription => (
          <SubscriptionType
            subscription={subscription}
            isButton={isButton}
            currentSub={currentSub}
            key={subscription.id}
            onSubscriptionChange={handleSubscriptionChange}
            userId={userId}
            setVisibilityPaymentModal={setVisibilityPaymentModal}
            setSubscriptionId={setSubscriptionId}
          />
        ))}
      </div>
      <div className="Subscription__additional-box">
        <div className="Subscription__additional-item Subscription__additional-item--small">
          <div className="SubscriptionType__title-wrapper">
            <h2 className="SubscriptionType__title">Shipping</h2>
          </div>
          <p className="SubscriptionType__p">Arrange your own shipping</p>
        </div>
        <div className="Subscription__additional-item Subscription__additional-item--large">
          <div className="SubscriptionType__title-wrapper">
            <h2 className="SubscriptionType__title">Shipping rates</h2>
          </div>
          <ul>
            <li className="SubscriptionType__rates SubscriptionType__rates--first">
              Shipping <span>See list</span>
            </li>
            <li className="SubscriptionType__rates">Excise & taxes <span>actual cost</span></li>
          </ul>
        </div>
      </div>
    </div>
  );
};


export default Subscription;