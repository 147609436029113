import { useParams } from 'react-router-dom';
import {TopButtons} from './TopButtons/TopButtons'
import { useEffect, useState } from 'react';
import { BASE_URL } from '../../api/constants';
import {requestInstance} from '../../services/AuthService/AccessTokenUpdateSevice'
import { SideBar } from './SideBar/SideBar';
import { WareHousesHeader } from './WareHousesHeader/WareHousesHeader';
import { WareHousesCatalog } from './WareHousesCatalog/WareHousesCatalog';
import { WareHousesDescription } from './WareHousesDescription/WareHousesDescription';
import { TeamMembers } from './TeamMembers/TeamMembers'
import { Cocktails } from './Cocktails/Cocktails'
import { Gallery } from './Gallery/Gallery'
import { Review } from './Review/Review'
import {ToastContainer } from 'react-toastify';


const LoadingIndicator = () => {}
export const WareHousesDetailPage = () => {
  const [currenWareHouse, setCurrentWareHouse] = useState(null);
  const [loading, setLoading] = useState(true);
  const { number } = useParams();
  const [chosenComponent, setChosenComponent] = useState('catalog')
  const [productFilter, setProductFilter] = useState('')
  const handleComponentClick = (component) => {
    setChosenComponent(component);
  };
  const getComponentStyle = (component) => {
    if (chosenComponent === component) {
      return { color: 'black', cursor: 'pointer'}; 
    }
    return { color: 'gray', cursor: 'pointer' }; 
  };


  useEffect(() => {
    const fetchWareHouse = async () => {
      try {
        setLoading(true);
        const url = `${BASE_URL}ware-houses/${number}`;
        const response = await requestInstance.get(url);
        console.log(response.data);
        setCurrentWareHouse(response.data);
      } catch (error) {
        console.error('Failed to fetch warehouses:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchWareHouse();
  }, [number]);

  return (
    <>
      {loading ? (
        <LoadingIndicator />
      ) : currenWareHouse ? (
        <>
            <TopButtons isMyWareHouse={currenWareHouse.is_my_warehouse} wareHouseId={currenWareHouse.id} />
            <div style={{display: 'flex'}}>
              <div><SideBar currenWareHouse={currenWareHouse} setProductFilter={ setProductFilter } productFilter={productFilter} /></div>
              <div>
                <WareHousesHeader currenWareHouse={currenWareHouse} />
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginLeft: '40px',
                  marginRight: '40px',
                  marginTop: '50px',
                  borderBottom: '1px solid gray',
                  paddingBottom: '10px',
                  color: 'gray',
                  fontSize: '26px',
                  fontWeight: '500'
                }}>
                   <div
        style={getComponentStyle('catalog')}
        onClick={() => handleComponentClick('catalog')}
      >
        Catalog
      </div>
      <div
        style={getComponentStyle('about')}
        onClick={() => handleComponentClick('about')}
      >
        About us
      </div>
      <div
        style={getComponentStyle('team')}
        onClick={() => handleComponentClick('team')}
      >
        Team
      </div>
      <div
        style={getComponentStyle('menu')}
        onClick={() => handleComponentClick('menu')}
      >
        Our Cocktails
      </div>
      <div
        style={getComponentStyle('gallery')}
        onClick={() => handleComponentClick('gallery')}
      >
        Gallery
      </div>
      </div>
                {chosenComponent === 'catalog' && <div> <WareHousesCatalog wareHouseId={currenWareHouse.id} isMyWarehouse={currenWareHouse.is_my_warehouse} productFilter={productFilter} /> </div>}
                {chosenComponent === 'about' && <div> <WareHousesDescription wareHouseDescription={currenWareHouse.description} /> </div>}
                {chosenComponent === 'team' && <div> <TeamMembers wareHouseId={currenWareHouse.id} /> </div>}
                {chosenComponent === 'menu' && <div> <Cocktails companyId={currenWareHouse.company.id} /> </div>}
                {chosenComponent === 'gallery' && <div> <Gallery wareHouseId={currenWareHouse.id} /> </div>}
                <Review wareHouseId={currenWareHouse.id} />
              </div>
            </div> 
            
        </>
      ) : (
        <div>No warehouse data found.</div>
      )}
    <ToastContainer />
    </>
  );
};

export default WareHousesDetailPage;