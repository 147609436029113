import { WareHousesType } from '../WareHouses/WareHousesType/WareHousesType'
import LocationList from './LocationList/LocationList';
import { useState } from 'react';

export const LocationPage = () => {
  const [listWareHouses, setListWareHouses] = useState([])
  const [selectedType, setSelectedType] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({
    countries: [],
    ratings: [],
    types: []
  });

  const handleTypeSelect = (type) => {
    setSelectedType(type);
  };
  const locations = listWareHouses.map(item => {
    console.log(item);
    if (item.current_coordinates.lat === null || item.current_coordinates.lng === null) {
      return null;
    } 
    return {
      lat: item.current_coordinates.lat,
      lng: item.current_coordinates.lng,
      name: item.name,
      description: item.description
    };
  }).filter(item => item !== null);
  const handleSelectedFilters = (filters) => {
    setSelectedFilters(filters)
  }
  return (
    <>
      <WareHousesType
        onSelectType={handleTypeSelect}
        selectedType={selectedType}
        onSelectFilters={handleSelectedFilters}
        selectedFilters={selectedFilters}
      />
      <LocationList
        selectedType={selectedType}
        locations={locations}
        setList={setListWareHouses}
        selectedFilters={selectedFilters}
      />
    </>
  )
}

export default LocationPage;