import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { BASE_URL } from '../../api/constants';
import { requestInstance } from '../../services/AuthService/AccessTokenUpdateSevice'
import {ActionHeaderButtons} from './ActionHeaderButtons/ActionHeaderButtons'
import { ProductSideBar } from './ProductSideBar/ProductSideBar';
import { ProductImages } from './ProductImages/ProductImages';
import {ProductPrice } from './ProductPrice/ProductPrice'

const LoadingIndicator = () => <div>Loading...</div>;

export const ProductDetailPage = () => {
  const [currentProduct, setCurrentProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const { number } = useParams();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const url = `${BASE_URL}products/${number}`;
        const response = await requestInstance.get(url);
        console.log(response.data);
        setCurrentProduct(response.data);
      } catch (error) {
        console.error('Failed to fetch product:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [number]);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (!currentProduct) {
    return <div>Product not found</div>;
  }

  return (
    <div>
      <ActionHeaderButtons currentProduct={currentProduct} />
      <div style={{display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '10px'}}>
        <ProductSideBar currentProduct={currentProduct}/>
        <ProductImages currentProduct={currentProduct}/>
        <ProductPrice currentProduct={currentProduct}/>
      </div>
    </div>
  );
}

export default ProductDetailPage;
