import { useEffect, useState, useCallback } from 'react';
import './CocktailsList.scss';
import { BASE_URL } from '../../../api/constants';
import requestInstance from '../../../services/AuthService/AccessTokenUpdateSevice';
import { Pagination } from '../../../components/Pagination/Pagination';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import deleteImg from './images/delete.png';
import editImg from './images/edit.png';
import { Link } from 'react-router-dom';

export const CocktailsList = ({ setCurrentForm, setCurrentCocktail }) => {
  const [cocktails, setCocktails] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 5;
  const company_id = JSON.parse(localStorage.getItem('user')).company.id;

  const getData = useCallback(async (offset = 0) => {
    try {
      const cocktailsUrl = `${BASE_URL}ware-houses/cocktails/?company=${company_id}&limit=${itemsPerPage}&offset=${offset}`;

      const cocktailsResponse = await requestInstance.get(cocktailsUrl);

      setCocktails(cocktailsResponse.data.results);
      setNextPage(cocktailsResponse.data.next);
      setPrevPage(cocktailsResponse.data.previous);
      setTotalPages(Math.ceil(cocktailsResponse.data.count / itemsPerPage));
      setCount(cocktailsResponse.data.count);
    } catch (error) {
      console.error(error);
      toast.error('Failed to fetch cocktails');
    }
  }, [company_id, itemsPerPage]);

  useEffect(() => {
    const offset = (currentPage - 1) * itemsPerPage;
    getData(offset);
  }, [getData, currentPage]);

  const mainPhoto = (item) => item.picture;

  const handleDeleteCocktail = async (cocktailId) => {
    if (window.confirm('Are you sure you want to delete this cocktail?')) {
      try {
        await requestInstance.delete(`${BASE_URL}ware-houses/cocktails/${cocktailId}/`);
        setCocktails(cocktails.filter(cocktail => cocktail.id !== cocktailId));
        toast.success('Cocktail deleted successfully');
      } catch (error) {
        console.error(error);
        toast.error('Failed to delete cocktail');
      }
    }
  };

  return (
    <div className='CocktailsList'>
      <ToastContainer />
      <div className='CocktailsList__header'>
        <div className='CocktailsList__top'>
          <h2 className='CocktailsList__title'>My Cocktails ({count})</h2>
          <button
            onClick={() => {
              setCurrentForm('cocktailForm');
              setCurrentCocktail(null);
            }}
            className='CocktailsList__add-button'>+ Add new</button>
        </div>
      </div>

      <table className='CocktailsList__table'>
        <thead className='CocktailsList__table_name'>
          <tr>
            <th className='CocktailsList__table-header'>Cocktail</th>
            <th className='CocktailsList__table-header'>Description</th>
            <th className='CocktailsList__table-header'></th>
          </tr>
        </thead>
        <tbody>
          {cocktails.map((cocktail) => (
            <tr key={cocktail.id}>
              <td className='CocktailsList__cocktail CocktailsList__row_item'>
              <Link to={`/cocktails/${cocktail.id}`}><img src={mainPhoto(cocktail)} alt={cocktail.title} className='CocktailsList__cocktail-image' /></Link>
                <p><Link to={`/cocktails/${cocktail.id}`}>{cocktail.title}</Link></p> 
              </td>
              <td className='CocktailsList__row_item'>{cocktail.description}</td>
              <td className='CocktailsList__row_item CocktailsList__row_item2'>
                <img className='CocktailsList__icon' src={deleteImg} alt="delete" onClick={() => handleDeleteCocktail(cocktail.id)} />
                <img className='CocktailsList__icon' src={editImg} alt="edit" onClick={() => {
                  setCurrentForm('cocktailForm');
                  setCurrentCocktail(cocktail.id);
                }} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Pagination
        itemsPerPage={itemsPerPage}
        setObjects={setCocktails}
        setNextPage={setNextPage}
        setPrevPage={setPrevPage}
        nextPage={nextPage}
        prevPage={prevPage}
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
        count={count}
      />
    </div>
  );
};

export default CocktailsList;
