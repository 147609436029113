import './Filters.scss'
import React, { useState } from 'react';
import { Modal } from '../Modal/Modal';
import { getNames } from 'country-list';


export const Filters = ({ showFiltersForm, setShowFilters, wareHousesTypes, selectedFilters, onSelectFilters }) => {
  const [showMoreCountries, setShowMoreCountries] = useState(false);
  const [showMoreTypes, setShowMoreTypes] = useState(false);

  const handleClose = () => setShowFilters(false);

  const countries = getNames();
  const visibleCountries = showMoreCountries ? countries : countries.slice(0, 15);
  const visibleTypes = showMoreTypes ? wareHousesTypes : wareHousesTypes.slice(0, 15);

  const handleCountryChange = (event) => {
    const country = event.target.name;
    const isChecked = event.target.checked;
    onSelectFilters({ ...selectedFilters, countries: isChecked ? [...selectedFilters.countries, country] : selectedFilters.countries.filter(c => c !== country) });
  };

  const handleTypeChange = (event) => {
    const type = event.target.name;
    const isChecked = event.target.checked;
    onSelectFilters({ ...selectedFilters, types: isChecked ? [...selectedFilters.types, type] : selectedFilters.types.filter(t => t !== type) });
  };

  const handleRatingChange = (event) => {
    const rating = event.target.name;
    const isChecked = event.target.checked;
    onSelectFilters({ ...selectedFilters, ratings: isChecked ? [...selectedFilters.ratings, rating] : selectedFilters.ratings.filter(r => r !== rating) });
  };

  return (
    <Modal show={showFiltersForm} handleClose={handleClose} title={"Filters"}>
      <div className='Filter'>
        <div className='FilterBlock'>
          <h3 className='FiltersSubTitle'>Countries</h3>
          <div className='filtersContainer'>
          {visibleCountries.map((country, index) => (
            <div key={index} className='checkItem'>
              <input
                type="checkbox"
                id={`country-${index}`}
                name={country}
                checked={selectedFilters.countries.includes(country)}
                onChange={handleCountryChange}
              />
              <label htmlFor={`country-${index}`}>{country}</label>
            </div>
          ))}
            </div>
          {countries.length > 15 && (
            <span
              onClick={() => setShowMoreCountries(!showMoreCountries)}
              className='ShowMore'
            >
              {showMoreCountries ? 'Show less' : 'Show more'}
            </span>
          )}
        </div>

        <div className='FilterBlock'>
          <h3 className='FiltersSubTitle' >Average rating</h3>
          <div className='filtersContainer'>
          {[0, 1, 2, 3, 4, 5].map((rating) => (
            <div key={rating} className='checkItem'>
              <input
                type="checkbox"
                id={`rating-${rating}`}
                name={rating}
                checked={selectedFilters.ratings.includes(`${rating}`)}
                onChange={handleRatingChange}
              />
              <label htmlFor={`rating-${rating}`}>{rating}</label>
            </div>
          ))}
            </div>
        </div>

        <div className='FilterBlock'>
          <h3 className='FiltersSubTitle' >Include warehouse types:</h3>
          <div className='filtersContainer'>
          {visibleTypes.map((type) => (
            <div key={type.id} className='checkItem'>
              <input
                type="checkbox"
                id={`type-${type.id}`}
                name={type.name}
                checked={selectedFilters.types.includes(type.name)}
                onChange={handleTypeChange}
              />
              <label htmlFor={`type-${type.id}`}>{type.name}</label>
            </div>
          ))}
            </div>
          {wareHousesTypes.length > 15 && (
            <span onClick={() => setShowMoreTypes(!showMoreTypes)}  className='ShowMore'>
              {showMoreTypes ? 'Show less' : 'Show more'}
            </span>
          )}
        </div>
        <div className='ShowMore ClearAll' onClick={() => onSelectFilters(
          {
            countries: [],
            ratings: [],
            types: []
          })}>
             Clear all
            </div>
        
      </div>
    </Modal>
  );
};

export default Filters;
