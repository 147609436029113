import './BasketHeaderButtons.scss'
import {useNavigate } from 'react-router-dom'

export const BasketHeaderButtons = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); 
  };
  const handleContinueClick = () => {
    navigate('../products'); 
  };
  return (
    <div className='BasketHeaderButtons'>
      <div className='BasketHeaderButtons__left_container'>
        <button className='BasketHeaderButtons__back' onClick={handleBackClick}> { '< '}Back</button>
        <span className='BasketHeaderButtons__span'>Go back to search results</span>
      </div>
      <button className='BasketHeaderButtons__cotinue' onClick={handleContinueClick}>Continue shopping</button>
    </div>
  )
}