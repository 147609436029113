import React, { useEffect, useState } from 'react';
import './LeadList.scss';
import { BASE_URL } from '../../../api/constants';
import requestInstance from '../../../services/AuthService/AccessTokenUpdateSevice';
import Pagination from '../../../components/Pagination/Pagination';

export const LeadList = () => {
  const [leadList, setLeadList] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [count, setCount] = useState(0);
  const itemsPerPage = 10; 

  const fetchLeads = async (url) => {
    try {
      const response = await requestInstance.get(url);
      setLeadList(response.data.results);
      setNextPage(response.data.next);
      setPrevPage(response.data.previous);
      setCount(response.data.count);
      setTotalPages(Math.ceil(response.data.count / itemsPerPage));
    } catch (error) {
      console.error('Failed to fetch leads:', error);
    }
  };

  useEffect(() => {
    const initialUrl = `${BASE_URL}leads/leads?limit=${itemsPerPage}&offset=0`;
    fetchLeads(initialUrl);
  }, []);

  return (
    <div className="lead-list">
      <h1>My leads</h1>
      <div className="lead-list__container">
        <table className="lead-list__table">
          <thead>
            <tr>
              <th>Client Name</th>
              <th>Last Activity</th>
              <th>Category</th>
              <th>Contact</th>
              <th>Connects</th>
            </tr>
          </thead>
          <tbody>
            {leadList.map(lead => (
              <tr key={lead.id}>
                <td>{lead.ware_house.name}</td>
                <td>{lead.ware_house.company.country}</td>
                <td>{lead.ware_house.type_of_ware_house.name}</td>
                <td>{lead.ware_house.company.email}</td>
                <td><button className="contact-button">Contact</button></td>
              </tr>
            ))}
          </tbody>
        </table>
        {totalPages > 1 && (
          <Pagination
            itemsPerPage={itemsPerPage}
            setObjects={setLeadList}
            setNextPage={setNextPage}
            setPrevPage={setPrevPage}
            nextPage={nextPage}
            prevPage={prevPage}
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            count={count}
          />
        )}
      </div>
    </div>
  );
};

export default LeadList;
