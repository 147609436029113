import './RelatedCocktails.scss';
import React, { useState, useEffect } from 'react';
import { requestInstance } from '../../../services/AuthService/AccessTokenUpdateSevice';
import { BASE_URL } from '../../../api/constants';
import { useNavigate } from 'react-router-dom';
import star from './images/star.png'

export const RelatedCocktails = () => {
  const [cocktails, setCocktails] = useState([]);
  const itemsPerPage = 4
  const navigate = useNavigate();

  useEffect(() => {
    const getCocktails = async (url) => {
      try {
        const response = await requestInstance.get(url);
        setCocktails(response.data.results);
      } catch (error) {
        console.error('Failed to fetch cocktails:', error);
      } finally {
      }
    };
    
    let initialUrl = `${BASE_URL}ware-houses/cocktails/?limit=${itemsPerPage}`;
    getCocktails(initialUrl);
    
  }, []);
  const handleViewCocktailClick = (cocktailId) => {
    navigate(`/cocktails/${cocktailId}`);
  };
  return (
    <div>
      <h2 className='RelatedCocktails__title'>Related Cocktails</h2>
      <div className='RelatedCocktails__container'>
      {cocktails.map((cocktail) => (
            <div key={cocktail.id} className='Cocktail__card'>
              <img className='Cocktail__img' src={cocktail.picture} alt={cocktail.title} />
              <div className='Cocktail__rating'><img src={star} alt='src' /> {cocktail.average_rating}</div>
              <h2 className='Cocktail__title'>{cocktail.title}</h2>
              <button onClick={() => handleViewCocktailClick(cocktail.id)} className='Cocktail__button'>View Cocktail</button>
            </div>
      ))}
        </div>
    </div>
  )
}

export default RelatedCocktails;

