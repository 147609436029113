import './LocationList.scss'
import { useState, useEffect, useContext } from 'react';
import { requestInstance } from '../../../services/AuthService/AccessTokenUpdateSevice';
import { BASE_URL } from '../../../api/constants';
import { EmptyPage } from '../../../components/EmptyPage/EmptyPage'
import star from './images/star.png'
import { MapWithMarkers } from '../LocationMap/LocationMap'
import { SearchContext } from '../../../context/SearchContext'
import { Link } from 'react-router-dom'
import { Pagination } from '../../../components/Pagination/Pagination'

const LoadingIndicator = () => <></>
export const LocationList = ({ selectedType, locations, setList, selectedFilters }) => {
  const [wareHouses, setWareHouses] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0)
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 8;
  const { searchQuery } = useContext(SearchContext);

  useEffect(() => {
    const getWareHousesLocation = async (offset = 0) => {
      try {
        setLoading(true);
        let url = `${BASE_URL}ware-houses/?limit=${itemsPerPage}&offset=${offset}`;

        const params = new URLSearchParams();
        
        if (searchQuery) {
          params.append('name', searchQuery)
        }
        if (selectedType) {
          params.append('type_of_ware_house_id', selectedType);
        }

        if (selectedFilters.countries.length > 0) {
          params.append('countries', selectedFilters.countries.join(','));
        }

        if (selectedFilters.ratings.length > 0) {
          params.append('ratings', selectedFilters.ratings.join(','));
        }

        if (selectedFilters.types.length > 0) {
          params.append('types', selectedFilters.types.join(','));
        }

        url += `&${params.toString()}`;
    
        console.log('Request URL:', url);

        const response = await requestInstance.get(url);
        setWareHouses(response.data.results);
        setList(response.data.results)
        setNextPage(response.data.next);
        setPrevPage(response.data.previous);
        setTotalPages(Math.ceil(response.data.count / itemsPerPage));
        setCount(response.data.count)
      } catch (error) {
        console.error('Failed to fetch warehouses:', error);
      } finally {
        setLoading(false);
      }
    };
    getWareHousesLocation();
  }, [itemsPerPage, selectedType, setList, selectedFilters, searchQuery]);

  const mainPhoto = (item) => item.ware_house_image.find(img => img.main)?.image;

  return (<>
    <div className="location-page">
    <div className="location-list">
      <h1 className="location-list--title">Ware Houses</h1>
      {loading ? (
        <LoadingIndicator />
      ) : wareHouses.length > 0 ? (
          <div className="location-list__items">
              {wareHouses.slice(0, 2).map((item) => (
                <div className="location-list__item" key={item.id}>
                  <Link to={`/ware-house/${item.id}`}>
                <img className="location-list__image" src={mainPhoto(item)} alt={item.name} />
                <div className='location-list__title-container'>
                  <div className="location-list__name">{item.name}</div>
                  <div className="location-list__rating">
                    <img src={star} alt="star" className="location-list__star" />
                    {item.average_rating || 0}
                  </div>
                </div>
                <div className="location-list__description">
                  <div>{item.description}</div>
                    </div>
                    </Link>
              </div>
            ))}
            </div>
      ) : (
        <EmptyPage text="No warehouses available!" />
      )}
      </div>
      <MapWithMarkers locations={locations} />
      </div>
     <div className='location-list__items_2'>
            {wareHouses.slice(2).map((item) => (
              <div className="location-list__item" key={item.id}>
                <Link to={`/ware-house/${item.id}`}>
                <img className="location-list__image" src={mainPhoto(item)} alt={item.name} />
                <div className='location-list__title-container'>
                  <div className="location-list__name">{item.name}</div>
                  <div className="location-list__rating">
                    <img src={star} alt="star" className="location-list__star" />
                    {item.average_rating || 0}
                  </div>
                </div>
                <div className="location-list__description">
                  <div>{item.description}</div>
                  </div>
                  </Link>
              </div>
            ))}
    </div>
    <Pagination
                  itemsPerPage={itemsPerPage}
                  setObjects={setWareHouses}
                  setNextPage={setNextPage}
                  setPrevPage={setPrevPage}
                  nextPage={nextPage}
                  prevPage={prevPage}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setCurrentPage={setCurrentPage}
                  count={count}
                />
    </>
  );
};

export default LocationList;