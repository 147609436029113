import { useEffect, useState, useCallback } from 'react';
import './ProductsList.scss';
import { BASE_URL } from '../../../api/constants';
import requestInstance from '../../../services/AuthService/AccessTokenUpdateSevice';
import { Pagination } from '../../../components/Pagination/Pagination';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import deleteImg from './images/delete.png';
import editImg from './images/edit.png';
import { Link } from 'react-router-dom';

export const ProductsList = ({ setCurrentForm, setCurrentProduct }) => {
  const [currentCategory, setCurrentCategory] = useState('');
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 5;
  const company_id = JSON.parse(localStorage.getItem('user')).company.id;

  const getData = useCallback(async (offset = 0, category = '') => {
    try {
      const categoryFilter = category ? `&category=${category}` : '';
      const productsUrl = `${BASE_URL}products/?company=${company_id}&limit=${itemsPerPage}&offset=${offset}${categoryFilter}`;
      const categoriesUrl = `${BASE_URL}products/categories/?company=${company_id}`;

      const [productsResponse, categoriesResponse] = await Promise.all([
        requestInstance.get(productsUrl),
        requestInstance.get(categoriesUrl)
      ]);

      setProducts(productsResponse.data.results);
      setNextPage(productsResponse.data.next);
      setPrevPage(productsResponse.data.previous);
      setTotalPages(Math.ceil(productsResponse.data.count / itemsPerPage));
      setCount(productsResponse.data.count);

      setCategory(categoriesResponse.data.results);
    } catch (error) {
      console.error(error);
      toast.error('Failed to fetch products or categories');
    }
  }, [company_id, itemsPerPage]);

  useEffect(() => {
    const offset = (currentPage - 1) * itemsPerPage;
    getData(offset, currentCategory);
  }, [getData, currentPage, currentCategory]);

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setCurrentCategory(selectedCategory);
    setCurrentPage(1); 
  };

  const mainPhoto = (item) => item.images.find(img => img.main)?.image;

  const handleDeleteProduct = async (productId) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        await requestInstance.delete(`${BASE_URL}products/${productId}/`);
        setProducts(products.filter(product => product.id !== productId));
        toast.success('Product deleted successfully');
      } catch (error) {
        console.error(error);
        toast.error('Failed to delete product');
      }
    }
  };


  return (
    <div className='ProductsList'>
      <ToastContainer />
      <div className='ProductsList__header'>
        <div className='ProductsList__top'>
          <h2 className='ProductsList__title'>My products ({count})</h2>
          <button
            onClick={() => {
              setCurrentForm('productForm')
              setCurrentProduct(null)
            }} className='ProductsList__add-button'>+ Add new</button>
        </div>
        <div className='ProductsList__category_container'>
          <h3 className='ProductsList__category_title'>Category:</h3>
          <select
            className='ProductsList__category_select'
            value={currentCategory}
            onChange={handleCategoryChange}
          >
            <option value=''>All</option>
            {category.map((cat) => (
              <option value={cat.id} key={cat.id}>{cat.name}</option>
            ))}
          </select>
        </div>
      </div>

      <table className='ProductsList__table'>
        <thead className='ProductsList__table_name'>
          <tr>
            <th className='ProductsList__table-header'>Product</th>
            <th className='ProductsList__table-header'>Description</th>
            <th className='ProductsList__table-header'>Category</th>
            <th className='ProductsList__table-header'>Price</th>
            <th className='ProductsList__table-header'></th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product.id}>
              <td className='ProductsList__product ProductsList__row_item'>
              <Link to={`/product/${product.id}`}>
                  <img src={mainPhoto(product)} alt={product.name} className='ProductsList__product-image' />
                  </Link>
                  <p>{product.name}</p>
                
              </td>
              <td className='ProductsList__row_item'>{product.makers} / {product.alcohol_percentage}% / {product.production_year}</td>
              <td className='ProductsList__row_item'>{product.category.name}</td>
              <td className='ProductsList__row_item'>
                {product.currency} {parseFloat(product.price).toFixed(2)}
              </td>
              <td className='ProductsList__row_item'>
                <img className='ProductsList__icon' src={deleteImg} alt="delete" onClick={() => handleDeleteProduct(product.id)} />
                <img className='ProductsList__icon' src={editImg} alt="edit" onClick={() => {
              setCurrentForm('productForm')
              setCurrentProduct(product.id)
            }}/>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Pagination
        itemsPerPage={itemsPerPage}
        setObjects={setProducts}
        setNextPage={setNextPage}
        setPrevPage={setPrevPage}
        nextPage={nextPage}
        prevPage={prevPage}
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
        count={count}
      />
    </div>
  );
};
export default ProductsList;
