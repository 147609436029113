import './TopButtons.scss'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from '../../../api/constants';
import requestInstance from '../../../services/AuthService/AccessTokenUpdateSevice';

export const TopButtons = ({ isMyWareHouse, wareHouseId }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/profile', { state: { currentForm: 'productForm' } });
  };
    const handleBackClick = () => {
      navigate(-1); 
    };

    const addToLeads = async () => {
      try {
        const url = `${BASE_URL}leads/leads/`
        const response = await requestInstance.post(url, {
          "ware_house": wareHouseId,
          "user": JSON.parse(localStorage.getItem('user')).id
        });
        if (response.status === 201) {
          toast.success('Warehouse added to leads successfully!');
        } else {
          toast.error('Failed to add warehouse to leads');
        }
      } catch (error) {
        console.error('Failed to add warehouse to leads:', error);
        toast.error('Failed to add warehouse to leads');
      }
    };
  
  return (
    <div className="TopButtons__container">
      {isMyWareHouse === false &&
        <div className="TopButtons__back__container">
          <button className="TopButtons__back__button" onClick={handleBackClick}>
            {'<'} Back
          </button>
          <span className="TopButtons__back__text">
            Go back to search results
          </span>
        </div>
      }
      {isMyWareHouse === true ? (
        <div className="MyWareHouseButton__conteiner">
          <button className="MyWareHouseButton__edit">Edit Profile</button>
          <button className="MyWareHouseButton__add" onClick={handleClick}>Add New Product</button>
        </div>
      ) : (
        <div className="WareHouseButton__conteiner">
          <button className="WareHouseButton__lead" onClick={addToLeads}>Add to lead list</button>
          <button className="WareHouseButton__contact" >Contact Lead</button>
        </div>
      )
      }
      </div>
  )
}

export default TopButtons