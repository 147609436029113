import './SubscriptionType.scss';
import cn from 'classnames';
import requestInstance from '../../../services/AuthService/AccessTokenUpdateSevice';
import { BASE_URL } from '../../../api/constants';

export const SubscriptionType = ({
  subscription,
  isButton,
  currentSub,
  onSubscriptionChange,
  userId,
  setVisibilityPaymentModal,
  setSubscriptionId,
  setBodyMySub
}) => {
  const handleSignUp = async () => {
    try {
      const url = `${BASE_URL}subscriptions/check-subscription/${userId}/${subscription.id}/`;
      const response = await requestInstance.get(url);
      console.log(response.data);
      if (response.data.success) {
        onSubscriptionChange(subscription.id);
        const url_sub = `${BASE_URL}subscriptions/my-current-sub/`;
        const response = await requestInstance.get(url_sub);
        setBodyMySub(response.data);
      } else {
        setSubscriptionId(subscription.id);
        setVisibilityPaymentModal(true);
      }
    } catch (error) {
      console.error('Failed to activate subscription:', error);
    }
  };

  return (
    <div className={cn({ 'SubscriptionType': true, 'SubscriptionType__choosen': currentSub === subscription.id })}>
      <div className="SubscriptionType__title-wrapper">
        <h2 className="SubscriptionType__title">{subscription.subscription_type}</h2>
      </div>
      <h3 className="SubscriptionType__price">
        <span className="SubscriptionType__price__main">{subscription.currency}{subscription.price}.</span>
        <span className="SubscriptionType__price__second">00</span>
        <br />
        <span className="SubscriptionType__price__second">/month</span>
      </h3>
      <h4 className="SubscriptionType__topic__title">Marketplace sales fee</h4>
      <ul className="SubscriptionType__list">
        <li className="SubscriptionType__list__element">Your existing customers
          <span className="SubscriptionType__span">
            {subscription.fee_for_existing_customers} %
          </span>
        </li>
        <li className="SubscriptionType__list__element">Customers through Tyrrst
          <span className="SubscriptionType__span">
            {subscription.fee_for_new_customers_from_tyrrst} %
          </span>
        </li>
      </ul>
      <h4 className="SubscriptionType__topic__title">Payment</h4>
      <ul className="SubscriptionType__list">
        <li className="SubscriptionType__list__element">Accelerated payment (5 days)
          <span className="SubscriptionType__span">
            {subscription.fee_for_accelerated_payment} %
          </span>
        </li>
        <li className="SubscriptionType__list__element">30 day payment
          <span className="SubscriptionType__span">
            {subscription.fee_for_simple_payment} %
          </span>
        </li>
      </ul>
      {subscription.inventory_system && 
        <>
          <h4 className="SubscriptionType__topic__title">Tyrrst storage</h4>
          <ul className="SubscriptionType__list">
            <li className="SubscriptionType__list__element">{subscription.free_amount_pallets} pallets (1 SKU each) % <span className="checkmark">✔</span></li>
            <li className="SubscriptionType__list__element">Add pallet (1 SKU each)
              <span className="SubscriptionType__span">
                {subscription.currency}{subscription.price_for_pallet}/month
              </span>
            </li>
            <li className="SubscriptionType__list__element">Inventory system<span className="checkmark">✔</span></li>
          </ul>
        </>
      }
      {subscription.excise_solution &&
        <>
          <h4 className="SubscriptionType__topic__title">Tyrrst fulfilment</h4>
          <ul className="SubscriptionType__list SubscriptionType__list__last">
            <li className="SubscriptionType__list__element">Excise solution (Europe)<span className="checkmark">✔</span></li>
            <li className="SubscriptionType__list__element">Pick, pack & custom clearance
              <span className="SubscriptionType__span">
                {subscription.pick_pack_and_custom_clearance}%
              </span>
            </li>
          </ul>
        </>
      }
      {isButton && currentSub === subscription.id &&
        <button className="SubscriptionType__button SubscriptionType__button--selected">Selected</button>
      }
      {isButton && currentSub !== subscription.id &&
        <button className="SubscriptionType__button" onClick={handleSignUp}>Sign me up</button>
      }
    </div>
  );
};

export default SubscriptionType;
