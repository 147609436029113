import { useState } from 'react';
import openEye from './images/open-eye.png';
import closeEye from './images/close-eye.png';
import { requestInstance } from '../../services/AuthService/AccessTokenUpdateSevice';
import { BASE_URL } from '../../api/constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ForgotPasswordForm3 = ({ setIsForgotPasswordForm3Visible }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [passwordData, setPasswordData] = useState({
    new_password: "",
    confirm_password: ""
  });

  const handleChange = (e) => {
    setErrors({});
    const { name, value } = e.target;
    setPasswordData({ ...passwordData, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const url = BASE_URL + 'auth/forgot-password-step-3/';
      const email = localStorage.getItem('forgotPassword');
      const result = await requestInstance.post(url, { email, ...passwordData });
      
      if (result.status === 200) {
        toast.success('Password reset successfully!');
        setIsForgotPasswordForm3Visible(false);
      } else {
        console.log('Password reset failed');
      }
    } catch (error) {
      console.error('Password reset failed:', error);
      setErrors(error.response?.data || { general: "An error occurred" });
    }
  };

  return (
    <div className="LoginForm">
      <ToastContainer />
      <h2 className="LoginForm__title">
        Reset Password
        <span style={{ right: '-100px' }} className='closeFormExpert' onClick={() => setIsForgotPasswordForm3Visible(false)}>x</span>
      </h2>
      <form className="LoginForm__form" method='post' onSubmit={handleSubmit}>
        <div className="LoginForm__input-group">
          <label htmlFor="new_password">New Password</label>
          <div className="LoginForm__input-password">
            <input
              type={showPassword ? "text" : "password"}
              id="new_password"
              name="new_password"
              value={passwordData.new_password}
              onChange={handleChange}
            />
            <button
              type="button"
              className="LoginForm__toggle-password"
              onClick={togglePasswordVisibility}
            >
              <img
                src={showPassword ? openEye : closeEye}
                alt={showPassword ? "Hide password" : "Show password"}
              />
            </button>
            {errors.new_password && <p className="ErrorText">{errors.new_password[0]}</p>}
          </div>
        </div>

        <div className="LoginForm__input-group">
          <label htmlFor="confirm_password">Confirm Password</label>
          <div className="LoginForm__input-password">
            <input
              type={showPassword ? "text" : "password"}
              id="confirm_password"
              name="confirm_password"
              value={passwordData.confirm_password}
              onChange={handleChange}
            />
            <button
              type="button"
              className="LoginForm__toggle-password"
              onClick={togglePasswordVisibility}
            >
              <img
                src={showPassword ? openEye : closeEye}
                alt={showPassword ? "Hide password" : "Show password"}
              />
            </button>
            {errors.confirm_password && <p className="ErrorText">{errors.confirm_password[0]}</p>}
          </div>
        </div>

        {errors.general && <p className="ErrorText">{errors.general}</p>}

        <button type="submit" className="LoginForm__submit">Reset Password</button>
      </form>
    </div>
  );
};

export default ForgotPasswordForm3;
