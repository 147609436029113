import './WareHousesHeader.scss';
import facebookImg from './images/facebook.png';
import instagramImg from './images/instagram.png';
import youTubeImg from './images/youtobe.png';

export const WareHousesHeader = ({ currenWareHouse }) => {
  const mainPhoto = (item) => item.ware_house_image.find(img => img.main)?.image;
  console.log(mainPhoto(currenWareHouse))
  return (
    
    <div className='WareHousesHeader' style={{ backgroundImage: `url(${mainPhoto(currenWareHouse)})`, objectFit: "contain"}}>
      <div className='WareHousesHeader__overlay'></div>
      <div className='WareHousesHeader__content'>
        <img className='WareHousesHeader__logo' src={currenWareHouse.logo} alt={currenWareHouse.name} />
        <div className='WareHousesHeader__name'>{currenWareHouse.name}</div>
        <div className='WareHousesHeader__location'>{currenWareHouse.region} {currenWareHouse.address} {currenWareHouse.zip_code}</div>
        <div className='WareHousesHeader__icon_container'>
          {currenWareHouse.facebook_url && <a href={currenWareHouse.facebook_url}><img src={facebookImg } alt='facebook' /></a>}
          {currenWareHouse.instagram_url && <a href={currenWareHouse.instagram_url}><img src={instagramImg} alt='instagram' /></a>}
          {currenWareHouse.you_tube_url && <a href={currenWareHouse.you_tube_url }><img src={youTubeImg} alt='youTube' /></a>}
        </div>
      </div>
      </div>
    )
  
}

export default WareHousesHeader;