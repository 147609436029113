import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './ProductImages.scss';

export const ProductImages = ({ currentProduct }) => {
  const [mainImage, setMainImage] = useState(currentProduct.images.find(image => image.main) || currentProduct.images[0]);

  const handleThumbnailClick = (image) => {
    setMainImage(image);
  };

  return (
    <div className="ProductImages__detail">
      <div className="ProductImages__header">
        <img className="ProductImages__image" src={mainImage.image} alt={currentProduct.name} />
        <div className="ProductImages__rating">
          <span className="ProductImages__star">⭐</span>
          <span className="ProductImages__rating-value">{currentProduct.rating.toFixed(1)}</span>
        </div>
      </div>
      <div className="ProductImages__thumbnails">
        <Carousel 
          showThumbs={false} 
          showIndicators={false} 
          showStatus={false} 
          infiniteLoop={false} 
          useKeyboardArrows 
          emulateTouch 
          centerMode 
          centerSlidePercentage={25}
          dynamicHeight={false}
        >
          {currentProduct.images.map(image => (
            <div key={image.id} onClick={() => handleThumbnailClick(image)}>
              <img className="ProductImages__thumbnail" src={image.image} alt={`${currentProduct.name} thumbnail`} />
            </div>
          ))}
        </Carousel>
      </div>
      <div className="ProductImages__description">
        <p>{currentProduct.description}</p>
      </div>
    </div>
  );
};

export default ProductImages;
