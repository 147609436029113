import { useEffect, useState } from 'react';
import { BASE_URL } from '../../../api/constants';
import { requestInstance } from '../../../services/AuthService/AccessTokenUpdateSevice';
import next from './images/next.png'
import filterImg from './images/filters.png'
import cn from 'classnames';
import resetImg from './images/reset.png'
import { Filters } from '../../../components/Filters/Filters'


export const ProductsType = ( { onSelectType, selectedType, onSelectFilters, selectedFilters } ) => {
    const [productsTypes, setProductsTypes] = useState([]);
    const [showFiltersForm, setShowFiltersForm] = useState(false)
    const [startIndex, setStartIndex] = useState(0);
    const itemsPerPage = 8;
  
    useEffect(() => {
      const fetchAllProductsTypes = async () => {
        let allResults = [];
        let offset = 0;
        const limit = 50; 
        let hasMore = true;
        
        while (hasMore) {
          try {
            const url = `${BASE_URL}products/categories/?limit=${limit}&offset=${offset}&exist=true`;
            const response = await requestInstance.get(url);
            const { results, next } = response.data;
            
            allResults = [...allResults, ...results];
            
            if (next) {
              offset += limit;
            } else {
              hasMore = false;
            }
          } catch (error) {
            console.error('Failed to fetch list of ware-houses:', error);
            hasMore = false;
          }
        }
  
        setProductsTypes(allResults);
      };
  
      fetchAllProductsTypes();
    }, []);
  
    const handleNext = () => {
      setStartIndex((prevIndex) => {
        const newIndex = (prevIndex + itemsPerPage) % productsTypes.length;
        return newIndex;
      });
    };
  
    const handlePrevious = () => {
      setStartIndex((prevIndex) => {
        const newIndex = (prevIndex - itemsPerPage + productsTypes.length) % productsTypes.length;
        return newIndex;
      });
    };
  
    const getDisplayedItems = () => {
      let endIndex = startIndex + itemsPerPage;
      let items = productsTypes.slice(startIndex, endIndex);
      
      if (items.length < itemsPerPage) {
        items = [...items, ...productsTypes.slice(0, itemsPerPage - items.length)];
      }
  
      return items;
    };
  
    const displayedItems = getDisplayedItems();
  
  
  return (
    <>
      <Filters
        showFiltersForm={showFiltersForm}
        setShowFilters={setShowFiltersForm}
        wareHousesTypes={productsTypes}
        selectedFilters={selectedFilters}
        onSelectFilters={onSelectFilters}
      />  
    <div className='WareHousesType__bar'>
  <div className='WareHousesTypes'>
  {displayedItems.map((item) => (
    
    <div
      className={cn({
        'WareHousesTypes__container': true,
        'WareHousesTypes__container--selected': selectedType === item.id
      })}
      key={item.id}
      onClick={() => onSelectType(item.id)}>
        <div className='WareHousesTypes__content'>
          <img className='WareHousesTypes__icons' src={item.icon} alt={item.name} />
          <div className='WareHousesTypes__title'>{item.name}</div>
        </div>
      </div>
  ))}   <div>
        <img className='prerImage' src={next} alt='next' onClick={handlePrevious}/>
          <img className='nextImage' src={next} alt='next' onClick={handleNext} />
          </div>
  </div>
  <div className='WareHousesTypes__buttons'>
    <button className='Filter__button' onClick={() => setShowFiltersForm(true)}>
      <img className='Filter__img' src={filterImg} alt='filter' />
      Filters
    </button>
          <button className='Filter__button' onClick={() => {onSelectFilters(
          {
            countries: [],
            ratings: [],
            types: []
            })
            onSelectType(null)
          }}>
      <img className='Filter__img' src={resetImg} alt='reset'/>
      Reset
    </button>
  </div>
      </div>
      </>
  );
};

export default ProductsType;