import React, { useState, useEffect, useCallback } from 'react';
import './ShippingForm.scss';
import { requestInstance } from '../../../services/AuthService/AccessTokenUpdateSevice';
import { BASE_URL } from '../../../api/constants';

export const ShippingForm = ({ deliveryId = null, onCancel, onSave, initialData = {} }) => {
  const [address, setAddress] = useState({
    firstName: '',
    lastName: '',
    city: '',
    state: '',
    zip: '',
    address1: '',
    address2: ''
  });

  const fetchAddress = useCallback(async () => {
    try {
      const response = await requestInstance.get(`${BASE_URL}payments/delivery-address/${deliveryId}/`);
      const data = response.data;
      setAddress({
        firstName: data.first_name,
        lastName: data.last_name,
        city: data.city,
        state: data.state,
        zip: data.zip,
        address1: data.address_line_1,
        address2: data.address_line_2
      });
    } catch (error) {
      console.error('Failed to fetch address:', error);
    }
  }, [deliveryId]);

  useEffect(() => {
    if (deliveryId) {
      fetchAddress();
    } else if (initialData && Object.keys(initialData).length > 0) {
      setAddress({
        firstName: initialData.first_name || '',
        lastName: initialData.last_name || '',
        city: initialData.city || '',
        state: initialData.state || '',
        zip: initialData.zip || '',
        address1: initialData.address_line_1 || '',
        address2: initialData.address_line_2 || ''
      });
    }
  }, [deliveryId, initialData, fetchAddress]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddress(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSave = async () => {
    try {
      if (deliveryId) {
        await requestInstance.patch(`${BASE_URL}payments/delivery-address/${deliveryId}/`, {
          first_name: address.firstName,
          last_name: address.lastName,
          city: address.city,
          state: address.state,
          zip: address.zip,
          address_line_1: address.address1,
          address_line_2: address.address2,
        });
      } else {
        const companyId = JSON.parse(localStorage.getItem('user')).company.id;
        await requestInstance.post(`${BASE_URL}payments/delivery-address/`, {
          first_name: address.firstName,
          last_name: address.lastName,
          city: address.city,
          state: address.state,
          zip: address.zip,
          address_line_1: address.address1,
          address_line_2: address.address2,
          company: companyId
        });
      }
      onSave();
    } catch (error) {
      console.error('Failed to save address:', error);
    }
  };

  return (
    <div className='ShippingAddress__form'>
      <div className='ShippingAddress__formRow'>
        <div>
          <label>First Name</label>
          <input type="text" name="firstName" value={address.firstName} onChange={handleInputChange} />
        </div>
        <div>
          <label>Last Name</label>
          <input type="text" name="lastName" value={address.lastName} onChange={handleInputChange} />
        </div>
      </div>
      <div className='ShippingAddress__formRow'>
        <div>
          <label>City</label>
          <input type="text" name="city" value={address.city} onChange={handleInputChange} />
        </div>
        <div>
          <label>State</label>
          <input type="text" name="state" value={address.state} onChange={handleInputChange} />
        </div>
        <div>
          <label>ZIP</label>
          <input type="text" name="zip" value={address.zip} onChange={handleInputChange} />
        </div>
      </div>
      <div className='ShippingAddress__formRow'>
        <div>
          <label>Address line 1</label>
          <input type="text" name="address1" value={address.address1} onChange={handleInputChange} />
        </div>
        <div>
          <label>Address line 2 (optional)</label>
          <input type="text" name="address2" value={address.address2} onChange={handleInputChange} className="lastInput" />
        </div>
      </div>
      <div className='ShippingAddress__buttons'>
        <button onClick={handleSave}>{deliveryId ? 'Update Address' : 'Add Address'}</button>
        <button onClick={onCancel}>Cancel</button>
      </div>
    </div>
  );
};

export default ShippingForm;
