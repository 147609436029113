import './EmptyPage.scss';
import notFoundImg from './images/notFound.png'


export const EmptyPage = ({text}) => {
  return (
    <div className='EmptyPage'>
      <img className='EmptyPage__img' src={ notFoundImg } alt='not found'/>
      <div className='EmptyPage__text'>{ text }</div>
    </div>
  )
}

export default EmptyPage;