import './Gallery.scss'
import { useEffect, useState } from 'react';
import { BASE_URL } from '../../../api/constants';
import { requestInstance } from '../../../services/AuthService/AccessTokenUpdateSevice'
import { Pagination } from '../../../components/Pagination/Pagination'
import { EmptyPage } from '../../../components/EmptyPage/EmptyPage'

const LoadingIndicator = () => <></>
export const Gallery = ({ wareHouseId }) => {
  const [gallery, setGallery] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0)
  const [totalPages, setTotalPages] = useState(0);

  const itemsPerPage = 15; 

  useEffect(() => {
    const getGallery = async (url) => {
      try {
        setLoading(true);
        const response = await requestInstance.get(url);
        console.log(response.data.results);
        setGallery(response.data.results);
        setNextPage(response.data.next);
        setPrevPage(response.data.previous);
        setTotalPages(Math.ceil(response.data.count / itemsPerPage));
        setCount(response.data.count)
      } catch (error) {
        console.error('Failed to fetch gallery:', error);
      } finally {
        setLoading(false);
      }
    };
    if (wareHouseId) {
      let initialUrl = `${BASE_URL}ware-houses/images/?ware_house_id=${wareHouseId}&limit=${itemsPerPage}`;
      getGallery(initialUrl);
    }
  }, [wareHouseId]);


  return (
    <>
    <div className='Gallery'>
      {loading ? (
        <LoadingIndicator />
      ) : (
        gallery.length > 0 ? (
          gallery.map((item) => (
            <div key={item.id} className='Gallery__card'>
              <img className='Gallery__img' src={item.image} alt={`img-${item.id}`} />
            </div>
          ))
        ) : (
         <div className='TeamMembersEmpty'> <EmptyPage text="No available images!" /></div>
        ))}
    </div>
     <Pagination
     itemsPerPage={itemsPerPage}
     setObjects={setGallery}
     setNextPage={setNextPage}
     setPrevPage={setPrevPage}
     nextPage={nextPage}
     prevPage={prevPage}
     currentPage={currentPage}
     totalPages={totalPages}
     setCurrentPage={setCurrentPage}
     count={count}
      />
      </>
  );
};

export default Gallery;