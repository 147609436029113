import React, { useState, useEffect } from 'react';
import './ShippingAddress.scss';
import { requestInstance } from '../../../services/AuthService/AccessTokenUpdateSevice';
import { BASE_URL } from '../../../api/constants';
import deleteImg from './images/delete.png';
import editImg from './images/edit.png';
import ShippingForm from '../ShippingForm/ShippingForm';

export const ShippingAddress = ({setDeliveryAddressId}) => {
  const [addresses, setAddresses] = useState([]);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [editingAddressId, setEditingAddressId] = useState(null);
  const [selectedAddressId, setSelectedAddressId] = useState(null);

  useEffect(() => {
    fetchAddresses();
  }, []);

  useEffect(() => {
    if (addresses.length > 0) {
      setSelectedAddressId(addresses[0].id);
      setDeliveryAddressId(addresses[0].id)
    }
  }, [addresses, setDeliveryAddressId]);

  const fetchAddresses = async () => {
    try {
      const response = await requestInstance.get(`${BASE_URL}payments/delivery-address/`);
      const fetchedAddresses = response.data.results.map(addr => ({
        id: addr.id,
        name: `${addr.first_name} ${addr.last_name}`,
        address: `${addr.address_line_1}, ${addr.city}, ${addr.state}`,
        first_name: addr.first_name,
        last_name: addr.last_name,
        city: addr.city,
        state: addr.state,
        zip: addr.zip,
        address_line_1: addr.address_line_1,
        address_line_2: addr.address_line_2
      }));
      setAddresses(fetchedAddresses);
    } catch (error) {
      console.error('Failed to fetch addresses:', error);
    }
  };

  const handleAddNewClick = () => {
    setIsAddingNew(true);
    setEditingAddressId(null);
  };

  const handleEditClick = (id) => {
    setEditingAddressId(id);
    setIsAddingNew(false);
  };

  const handleCancelClick = () => {
    setIsAddingNew(false);
    setEditingAddressId(null);
  };

  const handleSave = () => {
    fetchAddresses();
    handleCancelClick();
  };

  const handleDeleteAddress = async (id) => {
    try {
      await requestInstance.delete(`${BASE_URL}payments/delivery-address/${id}/`);
      setAddresses(addresses.filter(address => address.id !== id));
    } catch (error) {
      console.error('Failed to delete address:', error);
    }
  };

  return (
    <div className='ShippingAddress'>
      <h2>Shipping Address</h2>
      <div className='ShippingAddress__container'>
        {addresses.map((addr) => (
          <div key={addr.id} className='ShippingAddress__address'>
            <div className='ShippingAddress__3'>
              <input type="radio" name="address" value={addr.id} checked={selectedAddressId === addr.id} onChange={() => { setSelectedAddressId(addr.id); setDeliveryAddressId(addr.id) }} />
              <div className='ShippingAddress__2'>
                <div>{addr.name}</div>
                <div>{addr.address}</div>
                <div>
                  <img className='ProductsList__icon' onClick={() => handleDeleteAddress(addr.id)} src={deleteImg} alt="delete" />
                  <img className='ProductsList__icon' onClick={() => handleEditClick(addr.id)} src={editImg} alt="edit" />
                </div>
              </div>
            </div>
            {editingAddressId === addr.id && (
              <ShippingForm
                deliveryId={editingAddressId}
                onCancel={handleCancelClick}
                onSave={handleSave}
                initialData={addr}
              />
            )}
          </div>
        ))}
        <div className='ShippingAddress__address'>
          <input type="radio" name="address" className='ShippingAddress__add' onClick={handleAddNewClick} />
          <span>Add new</span>
          {isAddingNew && (
            <ShippingForm
              onCancel={handleCancelClick}
              onSave={handleSave}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ShippingAddress;
