import { BASE_URL, STRIPE_PUBLIK_KEY } from '../../api/constants';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import requestInstance from '../../services/AuthService/AccessTokenUpdateSevice';
import './BillingForm.scss'

const stripePromise = loadStripe(STRIPE_PUBLIK_KEY);
console.log(stripePromise)

export const BillingForm = ({ onPaymentMethodAdded }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.error('[error]', error);
      setLoading(false);
      return;
    }

    console.log('PaymentMethod created:', paymentMethod);  // Log the paymentMethod details

    try {
      const response = await requestInstance.post(`${BASE_URL}payments/connect-payment-method/`, {
        payment_method_id: paymentMethod.id,
      });

      console.log('Response from server:', response);  // Log the response

      if (response.status === 200) {
        alert('Payment method connected successfully!');
        onPaymentMethodAdded();  // Call the callback to fetch the updated list
      } else {
        alert('Failed to connect payment method.');
      }
    } catch (err) {
      console.error('Error connecting payment method:', err);  // Log the error details
      alert('Failed to connect payment method.');
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className="billing-form">
      <CardElement className="billing-form__card-element" />
      <button type="submit" disabled={!stripe || loading} className="billing-form__button">
        {loading ? 'Connecting...' : 'Connect Payment Method'}
      </button>
    </form>
    
  );
};

export default BillingForm;