import { useLocation } from 'react-router-dom';
import { Header } from './Header/Header.jsx'
import { Pages } from '../pages/Pages.jsx'
import { Footer } from './Footer/Footer.jsx';
import { ScrollToTop } from './ScrollTop/ScrollTop.jsx';
import {SearchContext} from '../context/SearchContext.js'
import {useState} from 'react'

export const Main = () => {
  const location = useLocation();
  const isBlack = location.pathname === '/';
  const [searchQuery, setSearchQuery] = useState('');
  return (
    <>
      <SearchContext.Provider value={{ searchQuery, setSearchQuery }}>
      <ScrollToTop />
      <Header isBlack={isBlack} />
      <Pages />
        <Footer />
        </SearchContext.Provider>
    </>
  );
}
export default Main;