import React, { useEffect, useState } from 'react';
import requestInstance from '../../services/AuthService/AccessTokenUpdateSevice';
import { BASE_URL, STRIPE_PUBLIK_KEY } from '../../api/constants';
import classNames from 'classnames';
import './PaymentModal.scss';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import cardImg from './images/card.png';

const stripePromise = loadStripe(STRIPE_PUBLIK_KEY);

export const PaymentModal = ({ onClose, subscriptionId, userId, setCurrentSub, setBodyMySub }) => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchPaymentMethods = async () => {
    try {
      const response = await requestInstance.get(`${BASE_URL}payments/payment-methods/`);
      setPaymentMethods(response.data.results);
    } catch (error) {
      console.error('Error fetching payment methods:', error);
    }
  };

  const fetchDefaultPaymentMethod = async () => {
    try {
      const response = await requestInstance.get(`${BASE_URL}payments/get-default-method/`);
      setDefaultPaymentMethod(response.data.id);
    } catch (error) {
      console.error('Error fetching default payment method:', error);
    }
  };

  const handlePaymentMethodSelect = (id) => {
    setDefaultPaymentMethod(id);
  };

  useEffect(() => {
    fetchPaymentMethods();
    fetchDefaultPaymentMethod();
  }, []);

  return (
    <div className="ModalOverlay">
      <Elements stripe={stripePromise}>
        <PaymentModalContent
          paymentMethods={paymentMethods}
          defaultPaymentMethod={defaultPaymentMethod}
          setDefaultPaymentMethod={setDefaultPaymentMethod}
          handlePaymentMethodSelect={handlePaymentMethodSelect}
          loading={loading}
          setLoading={setLoading}
          onClose={onClose}
          subscriptionId={subscriptionId}
          userId={userId}
          setCurrentSub={setCurrentSub}
          setBodyMySub={setBodyMySub}
        />
      </Elements>
    </div>
  );
};

const PaymentModalContent = ({
  paymentMethods,
  defaultPaymentMethod,
  setDefaultPaymentMethod,
  handlePaymentMethodSelect,
  loading,
  setLoading,
  onClose,
  subscriptionId,
  userId,
  setCurrentSub,
  setBodyMySub
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [newCardSelected, setNewCardSelected] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    let paymentMethodId = defaultPaymentMethod;

    if (newCardSelected) {
      const cardElement = elements.getElement(CardElement);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        console.error('[error]', error);
        setLoading(false);
        return;
      }

      paymentMethodId = paymentMethod.id;
    }

    try {
      const response = await requestInstance.post(`${BASE_URL}subscriptions/pay-for-sub/`, {
        user_id: userId,
        subscription_id: subscriptionId,
        payment_method_id: paymentMethodId,
      });

      console.log('Response from server:', response);

      if (response.status === 201) {
        alert('Payment successful!');
        onClose();
        setCurrentSub(subscriptionId);
        const url = BASE_URL + 'subscriptions/my-current-sub/';
        const response = await requestInstance.get(url);
        setBodyMySub(response.data)
      } else {
        alert('Payment failed.');
      }
    } catch (err) {
      console.error('Error processing payment:', err);
      alert('Payment failed.');
    }

    setLoading(false);
  };

  return (
    <div className="PaymentModal">
      <div>
        <h2 className='PaymentModal__header'>Payments<span onClick={onClose} className='PaymentModal__close'>×</span></h2>
      </div>
      <form onSubmit={handleSubmit} className="payment-form">
        <div className="payment-form__new-card-selection">
          <input
            type="radio"
            name="paymentMethod"
            value="new"
            checked={newCardSelected}
            onChange={() => {
              setNewCardSelected(true);
              setDefaultPaymentMethod(null);
            }}
          />
          <div className="payment-form__card-wrapper">
            <CardElement className="payment-form__card-element" />
          </div>
        </div>
        <div className="PaymentModal__methods">
          {paymentMethods.map((method) => {
            const isSelected = !newCardSelected && defaultPaymentMethod === method.id;
            return (
              <div
                key={method.id}
                className={classNames('PaymentMethodCard__container', { 'selected': isSelected })}
                onClick={() => {
                  setNewCardSelected(false);
                  handlePaymentMethodSelect(method.id);
                }}
              >
                <input
                  type="radio"
                  name="paymentMethod"
                  value={method.id}
                  checked={isSelected}
                  onChange={() => {
                    setNewCardSelected(false);
                    handlePaymentMethodSelect(method.id);
                  }}
                />
                <img src={cardImg} alt="card" className='PaymentModal__img' />
                <div>**** **** **** {method.card.last4}</div>
              </div>
            );
          })}
        </div>
        <button type="submit" disabled={loading} className="payment-form__button">
          {loading ? 'Processing...' : 'Pay'}
        </button>
      </form>
    </div>
  );
};

export default PaymentModal;
