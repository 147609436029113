import { useState } from 'react';
import { BASE_URL } from '../../api/constants';
import { requestInstance } from '../../services/AuthService/AccessTokenUpdateSevice';

export const ForgotPasswordForm1 = ({ setIsForgotPasswordFormVisible, setIsLoginFormVisible, setIsForgotPasswordForm2Visible }) => {
  const [email, setEmail] = useState('');
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const url = BASE_URL + 'auth/forgot-password-step-1/';
      const response = await requestInstance.post(url, { email });
      if (response.status === 200) {
        localStorage.setItem('forgotPassword', email);
        setIsForgotPasswordForm2Visible(true)
        setIsForgotPasswordFormVisible(false)
        setIsError(false);
      } else {
        setIsError(true);
        setIsSuccess(false);
      }
    } catch (error) {
      console.error('Password reset failed:', error);
      setIsError(true);
      setIsSuccess(false);
    }
  };

  return (
    <div className="LoginForm">
      <h2 className="LoginForm__title">
        Forgot Password
        <span className='closeFormExpert' onClick={() => setIsForgotPasswordFormVisible(false)}>x</span>
      </h2>
      <form className="LoginForm__form" method="post" onSubmit={handleSubmit}>
        <div className="LoginForm__input-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setIsError(false);
              setIsSuccess(false);
            }}
            required
          />
        </div>
        {isError && <span className="ErrorText">User with this email dose not exist!</span>}
        {isSuccess && <span className="SuccessText">Password reset link has been sent to your email.</span>}
        <button type="submit" className="LoginForm__submit">Submit</button>
      </form>
      <div className="LoginForm__footer">
        <a
          href="#login"
          className="LoginForm__back"
          onClick={() => {
            setIsForgotPasswordFormVisible(false);
            setIsLoginFormVisible(true);
          }}
        >
          Back to login
        </a>
      </div>
    </div>
  );
};

export default ForgotPasswordForm1;
