import benefits from './images/benefits.png'
import './Benefits.scss';

export const Benefits = () => {
  return (
    <div className="Benefits">
      <h2 className="Benefits__title">Benefits</h2>
      <div className="Benefits__box">
      <ul className="Benefits__list">
        <li className="Benefits__list__element">
          <h3 className="Benefits__list__element__title">
            Only Place To Reach Horeca Customer
          </h3>
          <p className="Benefits__list__element__text">
              Enter the only active marketplace that connect brands
              <br />
              with horeca customers across Europe and receive
              <br />
              orders directly from buyers. Easy ordering, credit and
              <br />
              payment solutions.
          </p>
        </li>
        <li className="Benefits__list__element">
          <h3 className="Benefits__list__element__title">
            Pan European Logistic Solution
          </h3>
          <p className="Benefits__list__element__text">
              Get access to low cost central storage logistic with
              <br />
              fully compliant excise solution across European
              <br />
              countries. Send a small order directly to a Hotel in
              <br />
              Vianna.
          </p>
        </li>
        <li className="Benefits__list__element">
          <h3 className="Benefits__list__element__title">
            Increase sales, margins & markets served
          </h3>
          <p className="Benefits__list__element__text">
              Save 20%, 30% or more in mark-ups and costs. Share
              <br />
              savings with resellers - end-users buy more. Start
              <br />
              selling in many European countries at once! Plus
              <br />
              benefit from our loyalty program.
          </p>
        </li>
        <li className="Benefits__list__element">
          <h3 className="Benefits__list__element__title">
            Make Your Life Easier
          </h3>
          <p className="Benefits__list__element__text">
              Trade show  lost cost cooporation, data analytics,
              <br />
              brand ambassadors, markating solutions, curated
              <br />
              buyer meetings and sampling soltuions.
          </p>
        </li>
        </ul>
        
        <img src={benefits} className="Benefits__image" alt='benefits' />
        </div>
    </div>
  )
};

export default Benefits;