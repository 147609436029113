import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CocktailForm.scss';
import requestInstance from '../../../services/AuthService/AccessTokenUpdateSevice';
import { BASE_URL } from '../../../api/constants';

export const CocktailForm = ({ setCurrentForm, cocktailId = null }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const companyId = parseInt(user.company.id);
  
  const initialCocktailState = {
    picture: null,
    title: '',
    description: '',
    video: null,
    ingredients: [],
    product_ids: [],  // Массив ID выбранных продуктов
    company: companyId,
  };

  const [cocktail, setCocktail] = useState(initialCocktailState);
  const [products, setProducts] = useState([]); // Список продуктов пользователя
  const [selectedProducts, setSelectedProducts] = useState([]); // Массив выбранных продуктов

  useEffect(() => {
    // Получение списка продуктов пользователя
    const fetchProducts = async () => {
      try {
        const response = await requestInstance.get(`${BASE_URL}products/?company=${companyId}`);
        setProducts(response.data.results); 
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    if (cocktailId) {
      const fetchData = async () => {
        try {
          const cocktailUrl = `${BASE_URL}ware-houses/cocktails/${cocktailId}/`;
          const cocktailResponse = await requestInstance.get(cocktailUrl);
          setCocktail(cocktailResponse.data);

          // Преобразуем my_products в список selectedProducts (ID продуктов)
          const productIds = cocktailResponse.data.my_products.map(product => product.id);
          setSelectedProducts(productIds);  // Устанавливаем выбранные продукты

        } catch (error) {
          console.error(error);
        }
      };
      fetchData();
    }

    fetchProducts();
  }, [cocktailId, companyId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCocktail({ ...cocktail, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setCocktail({ ...cocktail, [name]: files[0] });
  };

  const handleSave = async () => {
    const formData = new FormData();
    formData.append('title', cocktail.title);
    formData.append('description', cocktail.description);
    formData.append('company', cocktail.company);
    console.log(cocktail.picture)
    if (cocktail.picture && !(typeof cocktail.picture === 'string')) {
      formData.append('picture', cocktail.picture);
  }
  
    if (cocktail.video) {
      formData.append('video', cocktail.video);
    }
  
    formData.append('ingredients', JSON.stringify(cocktail.ingredients));
    formData.append('product_ids', JSON.stringify(selectedProducts)); // Это должен быть массив чисел
  
    try {
      let response;
      if (cocktailId) {
        response = await requestInstance.patch(`${BASE_URL}ware-houses/cocktails/${cocktailId}/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      } else {
        response = await requestInstance.post(`${BASE_URL}ware-houses/cocktails/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      }
      console.log(response);
      toast.success('Cocktail saved successfully!');
      setCurrentForm('cocktails');
    } catch (error) {
      console.error(error);
      handleErrors(error);
    }
  };

  const handleErrors = (error) => {
    if (error.response && error.response.status === 400 && error.response.data) {
      const errorMessages = error.response.data;
      for (const field in errorMessages) {
        if (Array.isArray(errorMessages[field])) {
          errorMessages[field].forEach(message => {
            toast.error(`${field}: ${message}`);
          });
        } else {
          toast.error(`${field}: ${errorMessages[field]}`);
        }
      }
    } else {
      toast.error('Failed to save cocktail.');
    }
  };

  const handleSelectProducts = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions);
    const selectedIds = selectedOptions.map(option => parseInt(option.value)); // Конвертируем ID в числа
  
    setSelectedProducts(selectedIds); // Обновляем массив выбранных продуктов
  };

  return (
    <div className='CocktailForm'>
      <ToastContainer />
      <div className='CocktailForm__header'>
        <h2 className='CocktailForm__title'>{cocktailId ? 'Update Cocktail' : 'Create Cocktail'}</h2>
        <div className='CocktailForm__header__buttons'>
          <button className='CocktailForm__header__button CocktailForm__header__button--cancel' onClick={() => setCurrentForm('cocktails')}>Back</button>
          <button className='CocktailForm__header__button CocktailForm__header__button--save' onClick={handleSave}>Save</button>
        </div>
      </div>
      <div className='CocktailForm__section'>
        <div className='CocktailForm__row'>
          <div className='CocktailForm__field'>
            <h3 className='CocktailForm__field_title'>Title</h3>
            <input className='CocktailForm__input' name='title' value={cocktail.title} onChange={handleChange} />
          </div>
          <div className='CocktailForm__field'>
            <h3 className='CocktailForm__field_title'>Description</h3>
            <textarea className='CocktailForm__input' name='description' value={cocktail.description} onChange={handleChange} />
          </div>
        </div>
        <div className='CocktailForm__row'>
          <div className='CocktailForm__field'>
            <h3 className='CocktailForm__field_title'>Picture</h3>
            <input className='CocktailForm__input' type='file' name='picture' onChange={handleFileChange} />
          </div>
        </div>
        <div className='CocktailForm__row CocktailForm__field--long'>
          <div className='CocktailForm__field'>
            <h3 className='CocktailForm__field_title'>Select Products</h3>
            <select
              className='CocktailForm__input'
              multiple
              onChange={handleSelectProducts}
              value={selectedProducts}
            >
              {products.map(product => (
                <option key={product.id} value={product.id}>{product.name}</option>
              ))}
            </select>
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default CocktailForm;
