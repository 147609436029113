import './Reseller.scss';


export const Reseller = () => {
  return (
    <div className="Reseller">
      <h3 className="Reseller__title">Find your ideal reseller</h3>
      <div className="Reseller__container">
        <div className="Reseller__type Reseller__type--large Hotels">
          <span>Hotels</span>
        </div>
        <div className="Reseller__type Bars">
          <span>Bars</span>
        </div>
        <div className="Reseller__type Webshop">
          <span>Webshop</span>
        </div>
        <div className="Reseller__type Restaurants">
          <span>Restaurants</span>
        </div>
        <div className="Reseller__type Supermarkets">
          <span>Supermarkets</span>
        </div>
        <div className="Reseller__type Cafes">
          <span>Cafes</span>
        </div>
        <div className="Reseller__type Alcohol_shops">
          <span>Alcohol Shops</span>
        </div>
      </div>
    </div>
  );
}

export default Reseller;