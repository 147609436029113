import { Banner } from './Banner/Banner';
import { Benefits } from './Benefits/Benefits';
import { Features } from './Features/Features';
import { Subscription } from '../Subscribtion/Subscribtion/Subscribtion';
import { Poster } from './Poster/Poster';
import { Reseller } from './Reseller/Reseller';



export const HomePage = () => {
  return (
    <>
      <Banner /> 
      <Benefits />
      <Features />
      <Subscription isButton={false} />
      <Poster />
      <Reseller />
    </>  
  )
}
export default HomePage;