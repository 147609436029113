import axios from 'axios';
import { BASE_URL } from '../../api/constants';

const API_URL = BASE_URL + 'auth/';

class AuthService {
  login(username, password) {
    return axios.post(`${API_URL}login/`, { username, password })
      .then(response => {
        if (response.data.access_token) {
          return response.data;
        }
        throw new Error('Login failed');
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  refreshToken() {
    const user = this.getCurrentUser();
    return axios.post(`${API_URL}refresh-token/`, { refresh_token: localStorage.getItem('refresh_token') })
      .then(response => {
        localStorage.setItem('access_token', response.data.access_token)
        localStorage.setItem('user', JSON.stringify(user));
        return response.data.access_token;
      });
  }
}

export const authService = new AuthService();