import { useState } from "react";
import { BASE_URL } from '../../api/constants';
import { requestInstance } from '../../services/AuthService/AccessTokenUpdateSevice';

export const ForgotPasswordForm2 = ({ setIsForgotPasswordForm2Visible, setIsForgotPasswordForm3Visible }) => {
  const [code, setCode] = useState(new Array(6).fill(""));
  const [errors, setErrors] = useState('');

  const handleChange = (element, index) => {
    setErrors('');
    if (isNaN(element.value)) return;

    setCode([...code.map((d, idx) => (idx === index ? element.value : d))]);

    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handlePaste = (event) => {
    const paste = event.clipboardData.getData("text");
    if (paste.length === 6 && !isNaN(paste)) {
      const newCode = paste.split("");
      setCode(newCode);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const url = BASE_URL + 'auth/forgot-password-step-2/';
      const result = await requestInstance.post(url, {
        email: localStorage.getItem('forgotPassword'), // email from localStorage
        code: code.join("")
      });
      if (result.status === 200) {
        setIsForgotPasswordForm2Visible(false);
        setIsForgotPasswordForm3Visible(true);
      } else {
        console.log('Code verification failed');
      }
    } catch (error) {
      console.error('Code verification failed:', error);
      // Check if error response has data with the code message, else set a default error
      setErrors(error.response?.data?.code || 'An error occurred');
    }
  };

  return (
    <div className="LoginForm">
      <h2 className="LoginForm__title">
        Enter Code
        <span style={{ right: '-100px' }} className='closeFormExpert' onClick={() => setIsForgotPasswordForm2Visible(false)}>x</span>
      </h2>
      <form className="LoginForm__form" method='post' onSubmit={handleSubmit}>
        <div className="EmailConfirmationForm__input-group">
          {code.map((data, index) => (
            <input
              key={index}
              type="text"
              name="code"
              maxLength="1"
              className="EmailConfirmationForm__input"
              value={data}
              onChange={e => handleChange(e.target, index)}
              onFocus={e => e.target.select()}
              onPaste={index === 0 ? handlePaste : null} // Attach onPaste only to the first input
            />
          ))}
        </div>
        {errors && <p style={{ textAlign: 'center' }} className="ErrorText">{errors}</p>}
        <button type="submit" className="LoginForm__submit">Submit</button>
      </form>
    </div>
  );
};
  
export default ForgotPasswordForm2;
