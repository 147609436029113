import './BasketSummary.scss';
import { useState, useEffect } from 'react';
import { BASE_URL } from '../../../api/constants';
import { requestInstance } from '../../../services/AuthService/AccessTokenUpdateSevice';
import { useCallback } from 'react';

export const BasketSummary = ({setBlockVisability, blockVisability, setVat, setShippingf}) => {
  const [shipping, setShipping] = useState('express');
  const [summaryData, setSummaryData] = useState({
    subtotal: 0,
    vat: 0,
    shippingCost: 0,
    total: 0,
  });

  const fetchSummaryData = useCallback(async (shippingMethod) => {
    try {
      const response = await requestInstance.get(`${BASE_URL}basket/basket-pricing/`, {
        params: {
          VAT: true,
          shipping: shippingMethod === 'express' ? 'Express' : 'Standard'
        }
      });
      setSummaryData({
        subtotal: response.data.subtotal_price,
        vat: response.data.vat_sum,
        shippingCost: response.data.shipping_sum,
        total: response.data.total_amount,
      });
      setVat(true)
      setShippingf(shippingMethod)
    } catch (error) {
      console.error('Failed to fetch summary data:', error);
    }
  }, [setVat, setShippingf]);

  useEffect(() => {
    fetchSummaryData(shipping);
  }, [shipping, fetchSummaryData]);

  return (
    <div className='BasketSummary'>
      <h2>Summary</h2>
      <div className='BasketSummary__row'>
        <h3>Subtotal</h3>
        <p>${summaryData.subtotal}</p>
      </div>
      <div className='BasketSummary__row'>
        <h3>VAT (21%)</h3>
        <p>${summaryData.vat}</p>
      </div>
      <h3>Shipping</h3>
      <div className='BasketSummary__shipping'>
        <label>
          <input
            type='radio'
            name='shipping'
            value='express'
            checked={shipping === 'express'}
            onChange={() => setShipping('express')}
          />
          Express: 2-4 Days
          <span>${shipping === 'express' ? 7 : ''}</span>
        </label>
        <label>
          <input
            type='radio'
            name='shipping'
            value='standard'
            checked={shipping === 'standard'}
            onChange={() => setShipping('standard')}
          />
          Standard: 5-7 Days
          <span>${shipping === 'standard' ? 1 : ''}</span>
        </label>
      </div>
      <div className='BasketSummary__row'>
        <h3>Total</h3>
        <p>${summaryData.total.toFixed(2)}</p>
      </div>
      <button className='BasketSummary__button' onClick={() => setBlockVisability(!blockVisability)}>Check Out</button>
    </div>
  );
}

export default BasketSummary;

