import './SubscribtionProfile.scss';
import { SubscriptionType } from '../../Subscribtion/SubscriptionType/SubscriptionType';
import { getSubscription } from '../../../api/subscribtion/getSubscriptions';
import { useState, useEffect } from 'react';
import { requestInstance } from '../../../services/AuthService/AccessTokenUpdateSevice';
import { BASE_URL } from '../../../api/constants';
import { PaymentModal } from '../../../components/PaymentModal/PaymentModal';

export const SubscribtionProfile = () => {
  const isButton = true;
  const [bodyMySub, setBodyMySub] = useState(null);
  const [listSubscriptions, setListSubscriptions] = useState([]);
  const [currentSub, setCurrentSub] = useState(null);
  const [userId, setUserId] = useState(null);
  const [visibilityPaymentModal, setVisibilityPaymentModal] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(null);

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      setUserId(JSON.parse(user).id);
    }
  }, []);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const subscriptions = await getSubscription();
        setListSubscriptions(subscriptions.results || []);
      } catch (error) {
        console.error('Failed to fetch subscriptions:', error);
      }
    };

    fetchSubscriptions();
  }, []);

  useEffect(() => {
    const getMyCurrentSub = async () => {
      try {
        const url = BASE_URL + 'subscriptions/my-current-sub/';
        const response = await requestInstance.get(url);
        setCurrentSub(response.data.subscription.id);
        setBodyMySub(response.data);
        console.log(response);
      } catch (error) {
        console.error('Failed to fetch current subscription:', error);
      }
    };

    if (isButton) {
      getMyCurrentSub();
    }
  }, [isButton]);

  const handleSubscriptionChange = (newSubId) => {
    setCurrentSub(newSubId);
  };

  return (
    <div className='SubscribtionProfile'>
      <div>
        <h2 className='TeamMemberForm__title'>Current Subscription</h2>
        {bodyMySub && (
         <div className='SubscribtionProfile__current'>
         <div className='SubscribtionProfile__current__column'>
           <p className='SubscribtionProfile__current__label'>Current Plan:<span className='SubscribtionProfile__current__value'>{bodyMySub.subscription.subscription_type}</span></p>
           <p className='SubscribtionProfile__current__label'>Reference:<span className='SubscribtionProfile__current__value'>{bodyMySub.subscription.currency}{bodyMySub.subscription.price}</span></p>
           <p className='SubscribtionProfile__current__label'>Billing:<span className='SubscribtionProfile__current__value'>{bodyMySub.subscription.currency}{bodyMySub.subscription.price}</span></p>
         </div>
         <div className='SubscribtionProfile__current__column'>
           <p className='SubscribtionProfile__current__label'>Valid Until:<span className='SubscribtionProfile__current__value'>{bodyMySub.end_date}</span></p>
           <p className='SubscribtionProfile__current__label'>Start Date:<span className='SubscribtionProfile__current__value'>{bodyMySub.start_date}</span></p>
           <p className='SubscribtionProfile__current__label'>Next Invoice:<span className='SubscribtionProfile__current__value'>{bodyMySub.subscription.currency}{bodyMySub.subscription.price}</span></p>
         </div>
       </div>
        )}
      </div>
      {userId && visibilityPaymentModal && (
        <div className="ModalOverlay">
          <PaymentModal
            onClose={() => setVisibilityPaymentModal(false)}
            subscriptionId={subscriptionId}
            userId={userId}
            setCurrentSub={setCurrentSub}
            setBodyMySub={setBodyMySub}
          />
        </div>
      )}
      <div className='SubscribtionProfile__container'>
        {listSubscriptions.map(subscription => (
          <SubscriptionType
            subscription={subscription}
            isButton={isButton}
            currentSub={currentSub}
            key={subscription.id}
            onSubscriptionChange={handleSubscriptionChange}
            userId={userId}
            setVisibilityPaymentModal={setVisibilityPaymentModal}
            setSubscriptionId={setSubscriptionId}
            setBodyMySub={setBodyMySub}
          />
        ))}
      </div>
    </div>
  );
}

export default SubscribtionProfile;
