import './MyOrders.scss'

export const MyOrders = ({ order }) => {
  return (
    <div className="MyOrders">
      {order.order_items.map((item) => (
        <div key={item.id} className="MyOrders__item">
          <img src={item.product.images[0].image} alt={item.product.name} className="MyOrders__item-image" />
          <div className="MyOrders__item-details">
            <h3>{item.product.name}</h3>
            <p>Qty: {item.amount}</p>
          </div>
          <div className="MyOrders__item-status">
            <p>Status: {order.order_status}</p>
          </div>
          <div className="MyOrders__item-actions">
            <p>Expected Delivery: {new Date(order.expected_delivery).toLocaleDateString()}</p>
          </div>
        </div>
      ))}
    </div>
  );
};


export default MyOrders;