import './ProductSideBar.scss'

export const ProductSideBar = ({ currentProduct }) => {
  return (
    <div className='ProductSideBar'>
      <div className='ProductSideBar__title'>NAME</div>
      <div className='ProductSideBar__info'>{currentProduct.name}</div>

      <div className='ProductSideBar__title'>AGE</div>
      <div className='ProductSideBar__info'>{currentProduct.age} years</div>

      <div className='ProductSideBar__title'>TYPE</div>
      <div className='ProductSideBar__info'>{currentProduct.category.name}</div>

      <div className='ProductSideBar__title'>MAKERS</div>
      <div className='ProductSideBar__info'>{currentProduct.makers}</div>

      <div className='ProductSideBar__title'>ALCOHOL PERCENTAGE</div>
      <div className='ProductSideBar__info'>{currentProduct.alcohol_percentage} %</div>

      <div className='ProductSideBar__title'>BOTTLE SIZE</div>
      <div className='ProductSideBar__info'>{currentProduct.bottle_size}</div>

      <div className='ProductSideBar__title'>Batch Number</div>
      <div className='ProductSideBar__info'>{currentProduct.batch_nr}</div>

      <div className='ProductSideBar__title'>PRODUCTION YEAR</div>
      <div className='ProductSideBar__info'>{currentProduct.production_year}</div>

      <div className='ProductSideBar__title'>NUMBER IN STOCK</div>
      <div className='ProductSideBar__info'>{currentProduct.numbers_in_stock}</div>

      <button className='ProductSideBar__button'>Contact lead</button>
    </div>
  );
};

export default ProductSideBar;
